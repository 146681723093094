import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AppContext from 'AppContext';
import UserPage from 'components/page/UserPage';
import { LoaderContainer } from 'components/ui/Loader';
import { ConfirmButton } from 'components/utils';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { manageFormErrors } from 'components/form/Form';
import { ChangePasswordToggle } from 'components/forms/ChangePasswordForm';
import DropdownEditable, { getOptionsFromAPI } from 'components/form/DropdownEditable';
import CheckboxEditable from 'components/form/CheckboxEditable';
import NotificationConfiguration from 'components/user/NotificationConfiguration';
import DesktopNotificationSwitch from 'components/user/DesktopNotificationSwitch';
import Avatar from 'components/ui/Avatar';
import OtpSettings from 'components/auth/OtpSettings';
import { ChangeEmailToggle } from 'components/forms/ChangeEmailForm';
import { addMessage } from 'components/ui/Messages';

function FieldUpdate(props) {
  const { label, field, user, onFieldChanged } = props;
  return (
    <div className="mb-4 me-3 field-value">
      <h5 className="h6">{ label }</h5>
      <FieldShowUpdate
        stateKey={'users:' + user.pk}
        field={field}
        pk={user.pk}
        api={user._genericApi}
        onSave={onFieldChanged}
        label={label}
        attributes={{ id: field }}>
        { usr => (
          !usr[field] ? (
            <span className="text-secondary"><Trans>Add a value</Trans></span>
          ) : usr[field]
        ) }
      </FieldShowUpdate>
    </div>
  );
}

function UserPageContent(props) {
  const { user, setUser, logout } = useContext(AppContext);
  const [isLoadedUser, setIsLoadedUser] = useState(false);
  const [meta, setMeta] = useState(null);
  const [error, setError] = useState();
  const [tab, setTab] = useState('account');

  useEffect(() => {
    user.load(user.pk).then(() => {
      setIsLoadedUser(true);
    }).catch(err => {
      setError(t`User can't be loaded`);
    });

    user.loadMeta().then((data) => {
      setMeta(data);
    }).catch(err => {
      setMeta(false);
      setError(t`Metas can't be loaded`);
    });
  }, [user, user.pk]);

  const onFieldChanged = (field, value, item) => {
    setUser(item);
  };

  const handleDelete = () => {
    setError(null);
    axios.delete(`/users/${user.pk}`).then((res) => {
      logout();
    }).catch(err => {
      const { globalErrors } = manageFormErrors(null, err, true);
      setError(globalErrors.map(e => e.error).join(<br/>));
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLangOpts = useCallback(
    getOptionsFromAPI('user-languages', '/users/meta', 'language'), []);

  if (!isLoadedUser || meta === null) {
    return <LoaderContainer height="4"/>;
  }

  if (error) {
    return <div className="alert alert-danger" role="alert">{ error }</div>;
  }

  const email = user.new_email === null ? user.email : user.new_email;
  const cancelNewEmail = () => {
    axios.put('/users/change-email', { new_email: null }).then((res) => {
      setUser(res.data);
      addMessage('email-change-canceled', t`E-mail`, t`Your e-mail change has been canceled`);
    }).catch(err => {
      addMessage('email-change-canceled-error', t`Unknown error`, t`Impossible to cancel e-mail change`);
    });
  };

  return (
    <>
      <ul className="d-md-none nav nav-tabs mb-3">
        <li className="nav-item">
          <button
            onClick={() => { setTab('account'); }}
            type="button"
            className={'nav-link' + (tab === 'account' ? ' active' : '')}>
            <Trans>Account settings</Trans>
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={() => { setTab('profile'); }}
            type="button"
            className={'nav-link' + (tab === 'profile' ? ' active' : '')}>
            <Trans>Profile</Trans>
          </button>
        </li>
      </ul>
      <div className="row">
        <div className={'change-email col-12 col-md-5 d-md-block' + (tab !== 'account' ? ' d-none' : '')}>
          <h5 className="h6"><Trans>E-mail</Trans></h5>
          <p data-cy="actual-email">{ email }</p>
          <ChangeEmailToggle user={user} email={email} setUser={setUser} />
          {user.new_email && (
            <div className="alert alert-warning mt-2" role="alert">
              <Trans>E-mail change in progress</Trans>
              <button type="button" onClick={cancelNewEmail} className="ms-2 btn btn-outline-secondary">
                Cancel e-mail change
              </button>
            </div>
          ) }
          <hr/>
          <h5 className="h6"><Trans>Password</Trans></h5>
          <ChangePasswordToggle/>
          <hr/>
          <h5 className="h6"><Trans>Two-factor authentication</Trans></h5>
          <OtpSettings user={user} setUser={setUser}/>
          <hr/>
          <h5 className="h6"><Trans>Communication language</Trans></h5>
          <DropdownEditable
            onUpdate={onFieldChanged}
            pk={user.pk}
            stateKey={'users:' + user.pk}
            api="/users"
            getOptions={getLangOpts}
            variant="light rounded-pill"
            field="language"
            fieldName={t`Communication language`}
            fieldValue="value"
            fieldLabel="label"
            required />
          <hr/>
          <h5 className="h6"><Trans>Notifications</Trans></h5>
          <NotificationConfiguration user_pk={user.pk}/>
          <DesktopNotificationSwitch user_pk={user.pk}/>
          <hr/>
          <h5 className="h6"><Trans>Account management</Trans></h5>
          <ConfirmButton
            variant="outline-danger"
            confirmLabel={t`Delete Permanently`}
            confirmVariant="danger"
            message={(
              <Trans>Are you sure you want to delete your user?</Trans>
            )}
            confirmValue={user.full_name}
            confirmValueLabel={t`Please confirm by typing your full name`}
            onConfirm={handleDelete}>
            <Trans>Delete my account permanently</Trans>
          </ConfirmButton>
        </div>
        <div className={'col-12 col-md-4 d-md-block' + (tab !== 'profile' ? ' d-none' : '')}>
          <FieldUpdate
            label={meta.fields.full_name.name}
            field="full_name"
            user={user}
            onFieldChanged={onFieldChanged} />
          <FieldUpdate
            label={meta.fields.private_phone.name}
            field="private_phone"
            user={user}
            onFieldChanged={onFieldChanged} />
          <FieldUpdate
            label={meta.fields.professional_phone.name}
            field="professional_phone"
            user={user}
            onFieldChanged={onFieldChanged} />
          <FieldUpdate
            label={meta.fields.mobile_phone.name}
            field="mobile_phone"
            user={user}
            onFieldChanged={onFieldChanged} />
        </div>
        <div className={'col-12 col-md-3 d-md-block mt-3 mt-md-0' + (tab !== 'account' ? ' d-none' : '')}>
          <h5 className="h6"><Trans>Avatar</Trans></h5>
          <Avatar user={user}/>
          <div className="my-3">
            <CheckboxEditable
              onUpdate={onFieldChanged}
              pk={user.pk}
              api={user._genericApi}
              field="gravatar"
              label="Use gravatar ?"
              type="switch"/>
          </div>
          <p className="my-3">
            <Trans>
              <a href="https://gravatar.com/">Create your gravatar account</a>
            </Trans>
          </p>
        </div>
      </div>
    </>
  );
}

export default function UserFormPage(props) {
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    t`My profile`
  ];

  return (
    <UserPage
      name="user-profile"
      title={t`My profile`}
      breadcrumb={breadcrumb}>
      <UserPageContent />

    </UserPage>
  );
}
