import React, { useContext, useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Datatable from 'components/datatable/Datatable';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import BacklogItemModal from 'components/modal/BacklogItemModal';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import { useStateItem } from 'utils/Item';
import { ColorSticker } from 'components/ui/ColorSticker';
import Icon from 'components/ui/Icon';
import DateFormat from 'components/ui/DateFormat';
import BulkItemsModal from 'components/modal/BulkItemsModal';
import BulkChangeProductModal from 'components/modal/BulkChangeProductModal';
import BacklogItem from 'entity/BacklogItem';
import BulkExportModal from 'components/modal/BulkExportModal';
import BulkPrintModal from 'components/modal/BulkPrintModal';

function BacklogItemsPageContent(props) {
  const { product, organization } = props;
  const { showModal } = useContext(BacklogModalContext);
  const refresh = useStateItem('backlog-items');

  const apiContext = useMemo(
    () => ({ product: product.pk, organization: organization.pk }),
    [product.pk, organization.pk]
  );
  const filtersData = useMemo(
    () => ({ product: product }),
    [product]
  );
  const customFilters = useMemo(
    () => ([
      {
        is_group: true,
        name: 'tagslist',
        label: t`Tags`,
        items: [
          {
            is_group: false,
            label: t`Tags`,
            name: 'tags_and',
            type: 'autocomplete',
            api: '/tags/' + product.organization.resource_slug
          },
        ]
      },
      {
        is_group: false,
        label: t`Main tasks`,
        name: 'isparent',
        type: 'BooleanField',
        buildParams: apiParams => {
          let ischild = false;
          let isparent = false;
          if (apiParams.has('isparent')) {
            apiParams.delete('isparent');
            isparent = true;
          }
          if (apiParams.has('ischild')) {
            apiParams.delete('ischild');
            ischild = true;
          }
          if (ischild !== isparent) {
            apiParams.append('subtasks', ischild ? 'children' : '-children');
          }
        }
      },
      {
        is_group: false,
        label: t`Subtasks`,
        name: 'ischild',
        type: 'BooleanField'
      }
    ]),
    [product.organization.resource_slug]
  );

  const filtersBase = ['status'];
  if (product.mode.value !== 'kanban') {
    filtersBase.push('sprint');
  }
  const filtersExtra = [
    'item_type', 'epic', 'assignee', 'parent', 'tags_and', 'fix_versions', 'affects_versions', 'need_estimate', 'isparent', 'ischild'
  ];
  const columnsList = [
    { name: 'reference', label: t`Reference`, active: true, locked: true },
    { name: 'item_type', label: t`Type`, active: true, group: t`Category` },
    { name: 'title', label: t`Name`, active: true, locked: true },
    { name: 'status', label: t`Status`, active: true },
    { name: 'created_at', label: t`Creation date` },
    { name: 'reporter', label: t`Reporter`, group: t`People` },
    { name: 'assignee', label: t`Assignee`, active: true, group: t`People` },
    { name: 'priority', label: t`Priority`, group: t`Category` },
    { name: 'subtasks_count', label: t`Subtasks`, group: t`Subtasks` },
    { name: 'parent', label: t`Parent`, group: t`Subtasks` },
    { name: 'parent_name', label: t`Parent name`, group: t`Subtasks` },
    { name: 'epic', label: t`Epic`, group: t`Category` },
    { name: 'tags', label: t`Tags`, group: t`Category` },
    { name: 'affects_versions', label: t`Affects versions`, group: t`Category` },
    { name: 'fix_versions', label: t`Fix versions`, group: t`Category` },
    { name: 'business_value', label: t`Business value`, group: t`Work` },
    { name: 'need_estimate', label: t`Need estimate`, group: t`Work` },
    { name: 'deadline', label: t`Deadline`, group: t`Work` },
  ];
  if (product.can_see_estimates) {
    if (product.estimate_mode.value === 'hours') {
      columnsList.push({ name: 'hours_estimate', label: t`Hours estimate`, group: t`Work` });
    }
    else {
      columnsList.push({ name: 'points_estimate', label: t`Points estimate`, group: t`Work` });
    }
  }
  if (product.can_see_worklogs) {
    columnsList.push({ name: 'total_work', label: t`Worklogs`, group: t`Work` });
  }
  if (product.mode.value === 'scrum') {
    columnsList.push({ name: 'sprint', label: t`Sprint` });
  }

  const selectedComps = [];
  if (product.can_edit_item) {
    selectedComps.push(BulkItemsModal);
    selectedComps.push(BulkChangeProductModal);
  }
  if (product.can_export_items) {
    selectedComps.push(BulkExportModal);
  }
  if (product.can_print_items) {
    selectedComps.push(BulkPrintModal);
  }
  return (
    <Datatable
      api="backlog-items"
      editHandler={false}
      canDelete={product.can_delete_item}
      deleteConfirmation={(
        <Trans><p>Are you sure you want to delete this backlog item?</p>
          <p>Deleting an item from the backlog will permanently delete
            all related comments, links, attachments and worklogs.</p></Trans>
      )}
      refresh={refresh}
      apiContext={apiContext}
      filtersData={filtersData}
      customFilters={customFilters}
      filtersBase={filtersBase}
      filtersExtra={filtersExtra}
      columnsKey={'blitems-product-' + product.pk}
      columnsList={columnsList}
      selectedComponents={selectedComps}
      Entity={BacklogItem}>
      <Datatable.Column name="reference">
        {(item) => (
          <a
            href={item.path}
            className="me-2"
            onClick={(e) => {
              e.preventDefault();
              showModal(item);
            }} >
            { item.resource_reference }
          </a>
        )}
      </Datatable.Column>
      <Datatable.Column name="item_type">
        {item => (item.item_type ? (
          <>
            <ColorSticker color={item.item_type.color} />
            <span className="label">{ item.item_type.resource_name }</span>
          </>
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="title"/>
      { product.mode.value === 'scrum' && (
        <Datatable.Column name="sprint"/>
      ) }
      <Datatable.Column name="status">
        {(item) => (
          <>
            { item.status.resource_name }
            { ['done', 'closed'].includes(item.status.status_type.value) && (
              item.resolution.value !== 'done' ? ` (${item.resolution.label})` : ''
            )}
          </>
        )}
      </Datatable.Column>
      <Datatable.Column name="created_at">
        {(item) => (
          <DateFormat
            datestring={item.created_at}
            hour12={false}
            year="numeric"
            month="short"
            day="numeric"
            hour="numeric"
            minute="numeric" />
        )}
      </Datatable.Column>
      <Datatable.Column name="reporter" />
      <Datatable.Column name="assignee" />
      <Datatable.Column name="epic">
        {item => (item.epic ? (
          <>
            <ColorSticker color={item.epic.color} />
            <span className="label">{ item.epic.resource_name }</span>
          </>
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="affects_versions">
        {(item) => item.affects_versions.map(v => v.resource_name).join(', ')}
      </Datatable.Column>
      <Datatable.Column name="fix_versions">
        {(item) => item.fix_versions.map(v => v.resource_name).join(', ')}
      </Datatable.Column>
      <Datatable.Column name="tags">
        {(item) => item.tags.join(', ')}
      </Datatable.Column>
      <Datatable.Column name="priority">
        {(item) => (item.priority.value !== 'undefined' ? item.priority.label : '')}
      </Datatable.Column>
      <Datatable.Column name="subtasks_count">
        {(item) => (item.subtasks_count > 0 ? item.subtasks_count : '')}
      </Datatable.Column>
      <Datatable.Column name="parent">
        {(item) => (item.parent ? (
          <a
            href={item.path}
            className="me-2"
            onClick={(e) => {
              e.preventDefault();
              showModal(item.parent);
            }} >
            { item.parent.resource_reference }
          </a>
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="parent_name">
        {(item) => (item.parent ? item.parent.resource_name : '')}
      </Datatable.Column>
      <Datatable.Column name="business_value" />
      { product.can_see_estimates && (product.estimate_mode.value === 'hours' ? (
        <Datatable.Column name="hours_estimate">
          {(item) => (item.hours_estimate ? item.hours_estimate.human_format : '')}
        </Datatable.Column>
      ) : (
        <Datatable.Column name="points_estimate">
          {(item) => (item.points_estimate === null ? '' : item.points_estimate)}
        </Datatable.Column>
      )) }
      { product.can_see_worklogs && (
        <Datatable.Column name="total_work">
          {(item) => (item.total_work ? item.total_work.human_format : '')}
        </Datatable.Column>
      ) }
      <Datatable.Column name="need_estimate">
        {(item) => (item.need_estimate ? (
          <Icon name="check" title={t`Need estimate`} />
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="deadline">
        {item => (item.deadline ? (
          <DateFormat datestring={item.deadline} />
        ) : '')}
      </Datatable.Column>
    </Datatable>
  );
}

export default function BacklogItemsPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Search items`
  ];
  const actions = product.can_add_item ? [
    <BacklogItemModal
      key="1"
      product={product}
      organization={organization}/>
  ] : [];

  return (
    <ProductPage
      name="backlog-items-list-page"
      title={t`Search items`}
      breadcrumb={breadcrumb}
      actions={actions}>
      <BacklogItemsPageContent
        organization={organization}
        product={product} />
    </ProductPage>
  );
}
