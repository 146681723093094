/* eslint-disable max-classes-per-file */
import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'dragonfly-ckeditor/build/ckeditor';
import 'dragonfly-ckeditor/build/translations/fr';
import AppContext from 'AppContext';
import uploadFile from 'components/utils/uploadFiles';
import { setStateItem } from 'utils/Item';
import { getBacklogItemFeedConfig, MentionCustomization } from './BacklogItemMention';
import { getMemberFeedConfig, getMemberMentionCustomization } from './MemberMention';
import 'scss/ckeditor.scss';

class CustomUploadAdapter {
  constructor(loader, backlogItem) {
    this.backlogItem = backlogItem;
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        uploadFile(file, {
          item: this.backlogItem.pk,
          onEditAttachment: data => {
            resolve({
              default: getFileUrl(data.file)
            });
            setStateItem('backlog-items:' + this.backlogItem.pk, { attachment_list: [
              ...this.backlogItem.attachment_list,
              data
            ] });
          },
          onError: err => { reject(err); }
        });
      }));
  }

  abort() {

  }
}

function getFileUrl(file) {
  const isImage = file['content-type'].substring(0, 6) === 'image/';
  return process.env.REACT_APP_FILE_URL +
    (isImage ? '/images/medium/' : '/') +
    file.key;
}

function getUploadAdapterPlugin(backlogItem) {
  return function(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = function(loader) {
      return new CustomUploadAdapter(loader, backlogItem);
    };
  };
}

export default function CKWrapper(props) {
  const { backlogItem, product, mention, onReady, ...rest } = props;
  const { lang } = useContext(AppContext);

  const feeds = [getBacklogItemFeedConfig(product)];
  const extraPlugins = [MentionCustomization];
  if (mention) {
    feeds.push(getMemberFeedConfig(product));
    extraPlugins.push(getMemberMentionCustomization());
  }
  if (backlogItem) {
    extraPlugins.push(getUploadAdapterPlugin(backlogItem));
  }

  const config = {
    language: {
      ui: lang.pk,
      content: lang.pk
    },
    toolbar: ['heading', '|', 'bold', 'italic', 'strikethrough', 'link', 'bulletedList', 'numberedList', 'codeBlock'],
    heading: {
      options: [
        { model: 'paragraph', title: t`Paragraph`, class: 'ck-heading_paragraph' },
        { model: 'heading3', view: 'h3', title: t`Heading 1`, class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: t`Heading 2`, class: 'ck-heading_heading4' }
      ]
    },
    link: {
      addTargetToExternalLinks: true
    },
    mention: {
      feeds: feeds
    },
    extraPlugins: extraPlugins
  };

  const onReadyHandler = (editor) => {
    if (onReady) onReady(editor);
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development'
      && !window.Cypress && process.env.REACT_APP_CK_DEBUG === true) {
      // eslint-disable-next-line import/no-extraneous-dependencies
      import('@ckeditor/ckeditor5-inspector').then((module) => {
        module.default.attach(editor);
      });
    }
  };

  if (backlogItem) {
    config.toolbar.push('filelist');
    config.filelist = {
      files: []
    };
    for (const attachment of backlogItem.attachment_list) {
      config.filelist.files.push({
        url: getFileUrl(attachment.file),
        type: attachment.file['content-type'],
        name: attachment.file.name
      });
    }
  }
  return (
    <CKEditor
      editor={Editor}
      config={config}
      onReady={onReadyHandler}
      {...rest}
    />
  );
}
