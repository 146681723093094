import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import { Alert, Button } from 'react-bootstrap';
import { Form, FormContext, FormContextProvider, FormFieldset } from 'components/form';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import { LoaderContainer } from 'components/ui/Loader';

function MemberForm(props) {
  const { item } = useContext(FormContext);
  const { organization } = props;
  const history = useHistory();

  const exclude_roles = [];
  if ((item.role ? item.role.value === 'role_guest' : true) &&
      organization.is_members_limit_reached) {
    exclude_roles.push('role_member', 'role_account_manager', 'role_organization_manager');
  }
  if ((item.role ? item.role.value !== 'role_guest' : true) &&
      organization.is_guests_limit_reached) {
    exclude_roles.push('role_guest');
  }

  return (
    <Form
      name="member-form"
      noValidate
      onSave={() => {
        organization.reload();
        history.push(`/${organization.slug}/manage/member`);
      }}>
      <FormFieldset legend={t`Personal infos`}>
        <Form.Field id="full_name" htmlSize="20" name="full_name" readOnly={item.user} />
      </FormFieldset>
      <FormFieldset legend={t`Contact infos`}>
        <Form.Field id="email" htmlSize="30" type="email" name="email" readOnly={item.user}/>
        {item.user && item.user.private_phone && (
          <div className="form-group">
            <div className="form-label"><Trans>Private phone</Trans></div>
            <div className="form-value">{ item.user && item.user.private_phone }</div>
          </div>
        )}
        {item.user && item.user.professional_phone && (
          <div className="form-group">
            <div className="form-label"><Trans>Professional phone</Trans></div>
            <div className="form-value">{ item.user && item.user.professional_phone }</div>
          </div>
        )}
        {item.user && item.user.mobile_phone && (
          <div className="form-group">
            <div className="form-label"><Trans>Mobile phone</Trans></div>
            <div className="form-value">{ item.user && item.user.mobile_phone }</div>
          </div>
        )}
      </FormFieldset>
      <FormFieldset legend={t`Permissions`}>
        {exclude_roles.includes('role_member') && (
          <Alert variant="warning"><Trans>
            You cannot add members because the organization’s membership limit has been reached.
          </Trans></Alert>
        )}
        {exclude_roles.includes('role_guest') && (
          <Alert variant="warning"><Trans>
            You cannot add guests because the organization’s guests number limit has been reached.
          </Trans></Alert>
        )}
        <Form.Select id="role" name="role" excludes={exclude_roles} required/>
      </FormFieldset>
      <div className="row">
        <div className="col-3"></div>
        <div className="col-9">
          <div className="actions">
            <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
            <NavLink className="btn btn-outline-secondary" to={`/${organization.slug}/manage/member`}><Trans>Cancel</Trans></NavLink>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default function MemberFormPage(props) {
  const { pk } = useParams();
  const organization = useContext(OrganizationContext);
  const [item, setItem] = useState({ organization: organization.pk });
  const [error, setError] = useState();

  useEffect(() => {
    if (pk) {
      axios.get(`/members/${pk}`).then(res => {
        setItem(res.data);
      }).catch(err => {
        setError(t`User can't be loaded`);
      });
    }
  }, [pk]);

  return (
    <AdminPage
      name={`member-form-page member-form-page__${item.pk ? 'edit' : 'new'}`}
      title={item.pk ? t`Edit  ${item.resource_name}` : t`Invite a new member`}>
      { error ? (
        <div className="alert alert-danger" role="alert">{ error }</div>
      ) : (
        <div className="relative">
          <FormContextProvider
            item={item}
            api="/members">
            { pk && !item.pk && (
              <LoaderContainer />
            ) }
            <MemberForm organization={organization} {...props}/>
          </FormContextProvider>
        </div>
      ) }
    </AdminPage>
  );
}
