import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Alert, Button, Form as BSForm, FormGroup, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import OrganizationContext from 'components/utils/OrganizationContext';
import { ConfirmButton } from 'components/utils';
import { manageFormErrors } from 'components/form/Form';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import AdminPage from 'components/page/AdminPage';
import copyTextToClipboard from 'components/utils/copyTextToClipboard';
import NotFoundPage from 'pages/NotFoundPage';
import { Form, FormContext, FormContextProvider } from 'components/form';
import HelpscoutNewItemPage from './HelpscoutNewItemPage';
import HelpscoutLinkItemPage from './HelpscoutLinkItemPage';

function SettingsForm(props) {
  const { organization } = props;
  const { item, setItem } = useContext(FormContext);

  useEffect(() => {
    setItem({ ...item, item_type: null });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.product]);

  return (
    <Form
      name="account-add-form"
      noValidate
      onSave={(data) => {}}
      style={{ width: '22em' }}>
      <Form.Many2One
        id="product"
        name="product"
        api={'/products?organization=' + organization.pk}/>
      { item.product && item.product.pk && (
        <Form.Many2One
          id="item_type"
          name="item_type"
          key={(item.product ? item.product.pk : 0) + (item.item_type ? item.item_type.pk : 0)}
          api={`/item-types?product=${item.product.pk}`}/>
      )}
      <div className="actions">
        <Button variant="primary" type="submit">
          <Trans>Save </Trans>
        </Button>
      </div>
    </Form>
  );
}

function HelpScoutSettings(props) {
  const history = useHistory();
  const { organization } = props;
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({
    app: {
      secret_key: null
    },
    mailboxes: {
      client_id: null,
      client_secret: null
    },
    settings: {
      product: null,
      itemType: null,
    }
  });
  const [errors, setErrors] = useState({});
  const [localErrors, setLocalErrors] = useState();
  const fields = ['client_id', 'client_secret'];

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/integrations`}><Trans key="2">Integrations</Trans></Link>,
    <span key="3">Help Scout</span>
  ];

  useEffect(() => {
    axios.get(`/integrations/helpscout/${organization.slug}/settings`).then(res => {
      setSettings(res.data);
    }).catch(err => {
      addMessage('load-helpscout-settings', t`Unknown error`, t`Can't load settings`);
    });
    setLoading(false);
  }, [organization.slug]);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    axios.put(`/integrations/helpscout/${organization.slug}/mailboxes`, {
      client_id: settings.mailboxes.client_id,
      client_secret: settings.mailboxes.client_secret,
    }).then(res => {
      window.location.href = res.data.url;
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        const { globalErrors, fieldsErrors } = manageFormErrors(fields, error);
        setErrors(fieldsErrors);
        setLocalErrors(globalErrors);
      } else {
        setLocalErrors([{
          key: 'error-0',
          error: t`Help Scout Mailbox API authorization failed.` }]);
      }
      setLoading(false);
    });
  };

  const handleRenewToken = e => {
    e.preventDefault();
    axios.post(`/integrations/helpscout/${organization.slug}/app/renew-token`).then(res => {
      setSettings(res.data);
    }).catch(error => {
      addMessage(
        'helpscout-renew-app-token-failed', t`Help Scout renew app token`, t`Secret token generation failed.`);
    });
  };

  const copyToken = () => {
    copyTextToClipboard(settings.app.secret_key);
    addMessage(
      'helpscout-copy-token', t`Help Scout`, t`Secret key copied in your clipboard.`);
  };

  const handleUninstall = () => {
    setLoading(true);
    axios.delete(`/integrations/helpscout/${organization.slug}`).then(res => {
      addMessage(
        'helpscout-uninstall-success', t`Help Scout uninstall`, t`Help Scout successfully uninstalled.`);
      organization.reload().then(() => {
        setLoading(false);
        history.push(`/${organization.slug}/integrations`);
      }).catch((err) => {
        addMessage('org-load', t`Unknown error`, t`Impossible to load organization`);
      });
    }).catch(error => {
      addMessage(
        'helpscout-uninstall-failed', t`Help Scout uninstall`, t`Help Scout uninstallation failed.`);
    });
  };

  const AUTHORIZE_URL = `${process.env.REACT_APP_PUBLIC_URL}/${organization.slug}/integrations/helpscout/authorize`;

  return (
    <AdminPage breadcrumb={breadcrumb} name="helpscout-page helpscout-page-config" title={t`Help Scout integration`}>
      { loading && (
        <LoaderContainer />
      ) }
      <div className="content-block-header">
        <h3>Mailbox API</h3>
        <Trans>
          In your Help Scout profile / “My Apps”, create an app with following settings:
          <ul>
            <li>App name: <code>Optera.io</code></li>
            <li>Redirection URL: <code>{AUTHORIZE_URL}</code></li>
          </ul>
          Then copy the “App ID” and “App Secret” fields content here and activate the integration.
        </Trans>
      </div>
      <BSForm autoComplete="off" onSubmit={handleSubmit}>
        { localErrors && localErrors.map((e) => (
          <Alert variant="danger" key={e.key}>{e.error}</Alert>
        ))}
        <FormGroup className="form-group" controlId="client_id">
          <BSForm.Label><Trans>App ID</Trans></BSForm.Label>
          <BSForm.Control
            required
            name="client_id"
            defaultValue={settings.mailboxes.client_id}
            onChange={e => setSettings({
              ...settings,
              mailboxes: { ...settings.mailboxes, client_id: e.target.value }
            })}
            role="presentation"
            autoComplete="off"
            autoFocus
            isInvalid={errors.client_id}
            style={{ width: '22em' }}/>
          {errors.client_id && (
            <BSForm.Control.Feedback type="invalid">
              {errors.client_id.join(<br/>)}
            </BSForm.Control.Feedback>
          )}
        </FormGroup>
        <FormGroup className="form-group">
          <BSForm.Label htmlFor="client_secret"><Trans>App secret</Trans></BSForm.Label>
          <BSForm.Control
            required
            name="client_secret"
            id="client_secret"
            onChange={e => setSettings({
              ...settings,
              mailboxes: { ...settings.mailboxes, client_secret: e.target.value }
            })}
            autoComplete="new-password"
            isInvalid={errors.client_secret}
            style={{ width: '22em' }}/>
          {errors.client_secret && (
            <BSForm.Control.Feedback type="invalid">
              {errors.client_secret.join(<br/>)}
            </BSForm.Control.Feedback>
          )}
        </FormGroup>
        <div className="mb-3">
          <button
            className="btn btn-primary"
            type="submit">
            {settings.app.secret_key ? (
              <Trans>New authorization</Trans>
            ) : (
              <Trans>Activate integration</Trans>
            )}
          </button>
        </div>
      </BSForm>
      {settings.app.secret_key && (
        <>
          <div className="content-block-header">
            <h3>Help Scout app</h3>
            <Trans>
              In Help Scout, create a “Custom App” (at <a
                href="https://secure.helpscout.net/apps">https://secure.helpscout.net/apps</a>),
              with following settings:
              <ul>
                <li>App name: <code>Optera.io</code></li>
                <li>Content Type: <code>Dynamic Content</code></li>
                <li>Callback Url: <code>{process.env.REACT_APP_API_URL}/api/integrations/helpscout/opteraio/html</code></li>
                <li>Secret Key: <i>The following secret key.</i></li>
              </ul>
            </Trans>
          </div>
          <BSForm.Label htmlFor="secret_key"><Trans>Optera.io secret key</Trans></BSForm.Label>
          <InputGroup className="mb-3 input-group-secret-token">
            <BSForm.Control
              required
              name="secret_key"
              id="secret_key"
              autoComplete="new-password"
              value={settings.app.secret_key}
              disabled/>
            <Button
              variant="primary"
              id="button-addon2"
              onClick={copyToken}>
              <Icon name="clipboard"/>
            </Button>
            <Button variant="secondary" id="button-renew-token" onClick={handleRenewToken}>
              <Trans>Renew</Trans>
            </Button>
          </InputGroup>
          <div className="content-block-header">
            <h3><Trans>Settings</Trans></h3>
          </div>
          <FormContextProvider item={settings.settings} api={`/integrations/helpscout/${organization.slug}/settings`}>
            <SettingsForm organization={organization}/>
          </FormContextProvider>
          <div className="helpscout-settings-actions mt-4">
            <ConfirmButton
              variant="danger"
              message={t`Are you sure you want to uninstall Help Scout integration?`}
              confirmLabel={t`Uninstall`}
              confirmVariant="danger"
              onConfirm={handleUninstall}>
              <Trans>Uninstall Help Scout integration</Trans>
            </ConfirmButton>
          </div>
        </>
      )}
    </AdminPage>
  );
}

function HelpScoutAuthorize(props) {
  const history = useHistory();
  const { organization } = props;
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/integrations`}><Trans key="2">Integrations</Trans></Link>,
    <span key="3"><Trans>Help Scout authorization</Trans></span>
  ];

  useEffect(() => {
    const redirect = () => {
      history.push(`/${organization.slug}/integrations/helpscout`);
    };

    if (code && state) {
      axios.post(`/integrations/helpscout/${organization.slug}/mailboxes`, {
        code: code,
        state: state,
      }).then(res => {
        addMessage(
          'helpscout-mailbox-success', t`Help Scout authorization`, t`Help Scout authorization successful.`);
        organization.reload().then(() => {
          redirect();
        }).catch((err) => {
          addMessage('org-load', t`Unknown error`, t`Impossible to load organization`);
          redirect();
        });
      }).catch(err => {
        addMessage(
          'helpscout-mailbox-failed', t`Help Scout authorization`, t`Help Scout authorization failed.`);
        redirect();
      });
    }
    redirect();
  }, [history, organization, code, state]);

  return (
    <AdminPage breadcrumb={breadcrumb} name="helpscout-page helpscout-page-authorize" title={t`Help Scout authorization`}>
      <LoaderContainer />
    </AdminPage>
  );
}

export default function HelpscoutPage(props) {
  const organization = useContext(OrganizationContext);
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>
  ];

  return (
    <Switch>
      <Route exact path="/:orgSlug/integrations/helpscout">
        <HelpScoutSettings organization={organization}/>
      </Route>
      <Route exact path="/:orgSlug/integrations/helpscout/authorize">
        <HelpScoutAuthorize organization={organization}/>
      </Route>
      <Route exact path="/:orgSlug/integrations/helpscout/new-item" component={HelpscoutNewItemPage} />
      <Route exact path="/:orgSlug/integrations/helpscout/link-item" component={HelpscoutLinkItemPage} />
      <Route>
        <NotFoundPage breadcrumb={breadcrumb} />
      </Route>
    </Switch>
  );
}
