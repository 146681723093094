import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Alert } from 'react-bootstrap';
import Organization from 'entity/Organization';
import { LoaderContainer } from 'components/ui/Loader';
import * as Pages from 'pages';
import OrganizationContext from 'components/utils/OrganizationContext';
import AdminPage from 'components/page/AdminPage';
import ContentBlock from 'components/content/ContentBlock';
import ProductList from 'components/product/ProductList';
import ProductModal from 'components/modal/ProductModal';
import OrgStats from 'components/organization/OrgStats';
import { useStateItem } from 'utils/Item';
import OrganizationIntegrationPage from 'integrations/OrganizationIntegrationPage';
import NotFoundPage from './NotFoundPage';
import DashboardProductPage from './DashboardProductPage';

function Dashboard(props) {
  const { organization } = props;
  const history = useHistory();
  const onAddProduct = (data) => {
    history.push('/' + data.organization.resource_slug + '/' + data.resource_slug);
  };
  const productsActions = [
    <Link key="1" to={`/${organization.slug}/manage/products`}>
      <Trans>See all</Trans>
    </Link>
  ];
  if (organization.can_add_product) {
    productsActions.push(
      <ProductModal
        organization={organization}
        onSave={onAddProduct}
        key="2" />);
    productsActions.push(
      <Link key="3" to={`/${organization.slug}/manage/products/import`}>
        <Trans>Import products</Trans>
      </Link>);
  }

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
  ];
  return (
    <AdminPage breadcrumb={breadcrumb} name="welcome-page" title={t`Welcome to Optera.io`}>
      <div className="row">
        <div className="col-md-3 col-lg-4 col-xl-3">
          <OrgStats organization={organization} />
        </div>
        <div className="col-md-9 col-lg-8 col-xl-9 ps-md-3">
          <ContentBlock
            title={t`${organization.name}’s products`}
            actions={productsActions}>
            <ProductList columns="3" organization={organization} onAddProduct={onAddProduct} />
          </ContentBlock>
        </div>
      </div>
    </AdminPage>
  );
}

export default function DashboardOrganizationPage() {
  const { orgSlug } = useParams();
  const [error, setError] = useState(null);
  const organization = useStateItem('organizations:' + orgSlug, true);

  useEffect(() => {
    if (!organization) {
      const org = new Organization();
      org.load(orgSlug).catch((err) => setError(err.response || err.message));
    }
  }, [orgSlug, organization]);

  if (error) {
    if (error.status && error.status === 404) {
      return <NotFoundPage />;
    }
    return <Alert variant="danger">Impossible de charger : { error}</Alert>;
  }
  if (!organization || orgSlug !== organization.slug) {
    return <LoaderContainer fullpage />;
  }
  return (
    <OrganizationContext.Provider value={organization}>
      <Switch>
        <Route exact path="/:orgSlug">
          <Dashboard organization={organization}/>
        </Route>
        <Route exact path="/:orgSlug/manage/settings" component={Pages.OrganizationSettingsPage}/>
        <Route exact path="/:orgSlug/manage/member/add" component={Pages.MemberForm}/>
        <Route exact path="/:orgSlug/manage/member/edit/:pk" component={Pages.MemberForm} />
        <Route exact path="/:orgSlug/manage/member" component={Pages.MemberList} />
        <Route exact path="/:orgSlug/manage/customers" component={Pages.CustomerList} />
        <Route exact path="/:orgSlug/manage/accounts" component={Pages.AccountList} />
        <Route exact path="/:orgSlug/manage/products" component={Pages.ProductListOrganization} />
        <Route path="/:orgSlug/manage/products/import" component={Pages.ProductImport} />
        <Route exact path="/:orgSlug/manage/worklogs" component={Pages.WorklogsPage} />
        <Route exact path="/:orgSlug/manage/subscriptions" component={Pages.OrganizationAddPage} />
        <Route path="/:orgSlug/integrations" component={OrganizationIntegrationPage} />
        <Route path="/:orgSlug/links/item/:reference" component={Pages.BacklogItemPageLink} />
        <Route path="/:orgSlug/:productSlug" component={DashboardProductPage} />
      </Switch>
    </OrganizationContext.Provider>
  );
}
