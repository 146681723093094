import React from 'react';

export default function FormFieldset(props) {

  return (
    <fieldset className="row">
      <legend className="col-3">{ props.legend }</legend>
      <div className="col-9">
        {props.children}
      </div>
    </fieldset>
  );
}
