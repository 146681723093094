/* eslint-disable prefer-object-spread */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import WorklogsContent from 'components/worklogs/WorklogsContent';

export default function WorklogsPage() {
  const organization = useContext(OrganizationContext);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Trans key="2">Work log</Trans>
  ];

  return (
    <AdminPage
      name="worklogs-page worklogs-page-org"
      title={t`Work log`}
      breadcrumb={breadcrumb}>
      <WorklogsContent organization={organization} />
      
    </AdminPage>
  );
}
