import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const FormContext = React.createContext({
  item: { pk: null },
  setItem: () => {},
  addErrorField: () => {},
  meta: {},
  errors: {},
  setErrors: () => {},
  fields: [],
  errorFields: [],
  registerField: () => {},
  isReady: false,
  version: 1
});

export function FormContextProvider(props) {
  const { onMetaLoaded } = props;
  const setItem = (item) => {
    setState(prevState => ({ ...prevState, item: item }));
  };
  
  const addErrorField = (field) => {
    setState(prevState => ({ ...prevState, errorFields: [...prevState.errorFields, field] }));
  };

  const setErrors = (errors) => {
    setState(prevState => ({ ...prevState, errors: errors }));
  };

  const registerField = (field) => {
    setState(prevState => ({
      ...prevState,
      fields: [...prevState.fields, field] }));
  };

  const initState = {
    item: (props.item && ('clone' in props.item)) ? props.item.clone() : { pk: null, ...props.item },
    setItem: setItem,
    addErrorField: addErrorField,
    meta: (('meta' in props) && props.meta) ? props.meta : {},
    errors: {},
    setErrors: setErrors,
    api: props.api,
    fields: [],
    registerField: registerField,
    errorFields: [],
    version: 1
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      item: (props.item && ('clone' in props.item)) ? props.item.clone() : { ...props.item },
      version: prevState.version + 1
    }));
  }, [props.item]);

  useEffect(() => {
    const loadMeta = async() => {
      const res = await axios.get(`${state.api}/meta`);
      if (onMetaLoaded) {
        onMetaLoaded(res.data);
      }
      setState(prevState => (
        { ...prevState, meta: res.data }));
    };

    if (!Object.keys(state.meta).length) {
      loadMeta();
    }
  }, [state.api, state.meta, onMetaLoaded]);

  return (
    <FormContext.Provider value={state}>
      {props.children}
    </FormContext.Provider>
  );
}
