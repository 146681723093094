import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import axios from 'axios';

function exportList(pks, searchParams) {
  const data = pks ? { pks: pks } : {};
  const url = 'backlog-items/export' + (searchParams ? ('?' + searchParams.toString()) : '');
  return axios.put(url, data);
}

function BulkExportModalContent(props) {
  const { pks, setShow, requestParams } = props;
  const [loading, setLoading] = useState(false);
  const [format, setFormat] = useState('csv');

  const setOpt = e => {
    setFormat(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    requestParams.set('format', format);
    exportList(pks, requestParams).then(data => {
      setLoading(false);
      addMessage(
        'export-sucess-' + data.data.job.pk,
        t`Export enqueued`,
        t`New export in queue. Please consult your job list to retrieve the file when finished.`
      );
      setShow(false);
    }).catch(error => {
      setLoading(false);
      addMessage('export-fail', t`Unknown error`, t`Impossible to export selected items`);
    });
  };

  return (
    <form className="relative" method="PUT" action="" onSubmit={onSubmit}>
      { loading && <LoaderContainer /> }
      <div className="form-group">
        <div className="label">
          <Trans>File format</Trans>
        </div>
        <Form.Check
          inline
          label={t`CSV`}
          name="export-format"
          type="radio"
          id="export-format-csv"
          value="csv"
          checked={format === 'csv'}
          onChange={setOpt}
        />
        <Form.Check
          inline
          label={t`Excel`}
          name="export-format"
          type="radio"
          id="export-format-xlsx"
          value="xlsx"
          checked={format === 'xlsx'}
          onChange={setOpt}
        />
      </div>
      <div className="form-group pt-2">
        <input type="submit" className="btn btn-primary me-2" value={t`Export`} />
        <input type="button" className="btn btn-outline-secondary" value={t`Cancel`} onClick={() => { setShow(false); }} />
      </div>

    </form>
  );
}

function BulkExportModal(props) {
  const { pks, dropdownItem, count, requestParams, className, limit } = props;
  const [show, setShow] = useState(false);
  if ((pks && pks.length < 1) || (!pks && !count)) {
    return null;
  }
  const size = count || pks.length;

  const classes = ['btn btn-outline-dark'];
  if (className) {
    classes.push(className);
  }
  if (dropdownItem) {
    classes.push('dropdown-item');
  }
  if (limit && limit < count) {
    classes.push('btn-disabled');
  }
  return (
    <>
      { limit && limit < count ? (
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip>
              <Trans>This action cannot be performed with more than { limit } elements.</Trans>
            </Tooltip>
          )}>
          <button type="button" className={classes.join(' ')}>
            <Trans>Export</Trans>
            <span className="badge text-bg-secondary ms-2">{ size }</span>
          </button>
        </OverlayTrigger>
      ) : (
        <button type="button" className={classes.join(' ')} onClick={() => { setShow(true); }}>
          <Trans>Export</Trans>
          {!dropdownItem && (
            <span className="badge text-bg-secondary ms-2">{ size }</span>
          )}
        </button>
      ) }
      
      <Modal
        enforceFocus={false}
        show={show}
        onHide={() => { setShow(false); }}
        className="bulk-items-modal"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="backlog-title">
            <Trans>Export {size} items</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BulkExportModalContent requestParams={requestParams} setShow={setShow} count={count} pks={pks} />
        </Modal.Body>
      </Modal>

    </>
  );
}
BulkExportModal.defaultProps = {
  count: null,
  pks: null,
  dropdownItem: false,
  requestParams: new URLSearchParams()
};

export default BulkExportModal;
