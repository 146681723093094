import React, { useContext, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormContextProvider } from 'components/form';
import HomePage from 'components/page/HomePage';
import AppContext from 'AppContext';
import { websiteUrl } from 'components/utils/urls';

function SignupForm() {
  const [sent, send] = useState();
  if (sent) {
    return (
      <>
        <h2><Trans>Thank you for registering</Trans></h2>
        <p>
          <Trans>
            You will receive a confirmation email shortly with a link to confirm your e-mail and choose your password.
          </Trans>
        </p>
      </>
    );
  }
  return (
    <Form
      id="register-form"
      onSave={() => { send(true); }}
      noValidate>
      <p><Trans>Welcome, to create your account please provide your name and e-mail address:</Trans></p>
      <Form.Field key="email" id="email" name="email" type="email" required />
      <Form.Field key="full_name" id="full_name" name="full_name" required />
      <p className="text-small">
        <Trans>
          By signing up, you agree to our <a href={websiteUrl('terms')} target="_blank" rel="noreferrer">terms of service</a>.
        </Trans>
      </p>
      <div className="actions text-center">
        <button className="btn btn-cta" type="submit"><Trans id="signup.action">Sign up</Trans></button>
        <Link className="btn btn-cta btn-cta__secondary" to="/auth/login"><Trans>Cancel</Trans></Link>
      </div>
    </Form>
  );
}

export default function SignupPage() {
  const { user } = useContext(AppContext);
  if (user) {
    return <Redirect to="/"/>;
  }
  const newUser = {};
  const campaign = window.sessionStorage.getItem('campaign');
  if (campaign) {
    try {
      newUser.campaign = JSON.parse(campaign);
    }
    catch {
      // do nothing
    }
  }

  return (
    <HomePage name="signup-form-page">
      <div className="cols">
        <div className="hero">
          <h1><Trans>Sign up now!</Trans></h1>
          <p><Trans>Start to use your SCRUM and Kanban project management software, quick to implement and easy to use!</Trans></p>
        </div>
        <div className="">
          <FormContextProvider item={newUser} api="/auth/signup">
            <SignupForm/>
          </FormContextProvider>
        </div>
      </div>

    </HomePage>
  );
}
