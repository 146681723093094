import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import DateFormat from 'components/ui/DateFormat';

export class D {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.points = [];
    this.addPoint();
  }

  addPoint() {
    this.points.push([this.x, this.y]);
  }

  lineTo(dx, dy) {
    this.x += dx;
    this.y += dy;
    this.addPoint();
  }

  lineToAbs(x, y) {
    this.x = x;
    this.y = y;
    this.addPoint();
  }

  round(n) {
    return Math.round(n * 100) / 100;
  }

  toString() {
    return 'M' +
      this.points
        .map(p => (this.round(p[0]) + ',' + this.round(p[1])))
        .join('L');
  }
}

const SvgEl = function(props) {
  const { title, w, h, data } = props;

  if (!data) {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}></svg>;
  }

  const xMargin = 1;
  const yMargin = 10;
  const insideMargin = 20;
  const axeD = new D(xMargin, 0);
  axeD.lineTo(0, h - yMargin);
  axeD.lineTo(w - (xMargin + 1), 0);
  const graphD = new D(xMargin + insideMargin, insideMargin);

  const vertX = [];
  const diff = (w - (xMargin + insideMargin + 1)) / data.days;
  for (let d = 0; d <= data.days; d++) {
    vertX.push(xMargin + insideMargin + (Math.round(100 * d * diff) / 100));
  }
  const availH = h - (yMargin + 2 * insideMargin);
  for (let d = 1; d <= data.data.length; d++) {
    const y = availH * (data.total - data.data[d]) / data.total;
    graphD.lineToAbs(
      xMargin + insideMargin + (Math.round(100 * d * diff) / 100),
      insideMargin + Math.round(100 * y) / 100
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
      <title>{ title }</title>
      <path className="st1" d={axeD.toString()}/>
      { vertX.map(vx => (
        <line key={vx} x1={vx} y1={h - yMargin} x2={vx} y2={h} className="st1" />
      )) }
      <line x1={xMargin + insideMargin} y1={insideMargin} x2={w} y2={h - yMargin - insideMargin} strokeDasharray="3" className="st2" />
      <path className="st3" d={graphD.toString()}/>
    </svg>
  );
};

export default function SprintBurndown(props) {
  const { product, sprint } = props;
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [currentSprint, setCurrentSprint] = useState(sprint);

  const loadData = useCallback(() => {
    axios.get('/sprints/' + currentSprint.pk + '/burndown').then(res => {
      setData(res.data);
    }).catch(err => {
      setData(false);
      setError(t`Impossible to show graph`);
    });
  }, [currentSprint]);

  useEffect(() => {
    if (!currentSprint) {
      axios.get('/sprints?meta=&current=true&product=' + product.pk).then(res => {
        if (res.data.items.length < 1) {
          setCurrentSprint(null);
        }
        else {
          setCurrentSprint(res.data.items[0]);
        }
      }).catch(err => {
        setError(t`Impossible to show graph`);
      });
    }
    else {
      loadData();
    }

  }, [product.pk, loadData, currentSprint]);

  if (!currentSprint && !error) {
    return null;
  }
  return (
    <div className="graph graph-burndown">
      <h3 className="fs-5 text-center"><Trans>Sprint burndown</Trans></h3>
      <div className="graph__legend">
        <span className="bar bar-1"></span> <Trans>Baseline</Trans>
        <span className="bar bar-2 ms-3"></span> <Trans>Remaining effort</Trans>
      </div>
      <div className="graph__inside">
        { data && (
          <div className="graph__graph">
            <SvgEl w="600" h="240" title={t`Sprint burndown`} data={data} />
          </div>
        ) }
        <div className="graph__yaxis">
          <Trans>Effort remaining</Trans>
        </div>
        <div className="graph__xaxis">
          { data && (
            <>
              <DateFormat month="long" year="numeric" day="numeric" datestring={data.starts_on}/> -{' '}
              <DateFormat month="long" year="numeric" day="numeric" datestring={data.ends_on}/>
            </>
          ) }
        </div>
        { error && (
          <div className="graph__error alert alert-danger">{ error }</div>
        ) }
      </div>

    </div>
  );
}
