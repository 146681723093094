import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DateFormat from 'components/ui/DateFormat';
import { D } from './SprintBurndown';

const SvgEl = function(props) {
  const { title, w, h, data, max } = props;

  if (!data) {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}></svg>;
  }

  const xMargin = 1;
  const yMargin = 10;
  const insideMargin = 20;
  const axeD = new D(xMargin, 0);
  axeD.lineTo(0, h - yMargin);
  axeD.lineTo(w - (xMargin + 1), 0);

  const vertX = [];
  const diff = (w - (xMargin + 2 * insideMargin + 1)) / (data.labels.length - 1);
  for (let d = 0; d <= data.labels.length; d++) {
    vertX.push(xMargin + insideMargin + (Math.round(100 * d * diff) / 100));
  }

  const drawLine = function(dataset) {
    const availH = h - (yMargin + 2 * insideMargin);
    let y = availH * (max - dataset[0]) / max;
    const graphD = new D(
      xMargin + insideMargin, insideMargin + Math.round(100 * y) / 100);
    for (let d = 1; d <= dataset.length; d++) {
      y = availH * (max - dataset[d]) / max;
      graphD.lineToAbs(
        xMargin + insideMargin + (Math.round(100 * d * diff) / 100),
        insideMargin + Math.round(100 * y) / 100
      );
    }
    return graphD;
  };

  const graphScope = drawLine(data.scope);
  const graphTodo = drawLine(data.todo);
  const graphDone = drawLine(data.done);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
      <title>{ title }</title>
      <path className="st1" d={axeD.toString()}/>
      { vertX.map(vx => (
        <line key={vx} x1={vx} y1={h - yMargin} x2={vx} y2={h} className="st1" />
      )) }
      <path className="st-todo" d={graphTodo.toString()}/>
      <path className="st-done" d={graphDone.toString()}/>
      <path className="st-scope" d={graphScope.toString()}/>
    </svg>
  );
};

export default function ProductBurnup(props) {
  const { product } = props;
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const groupBy = product.mode.value === 'kanban' ? 'day' : 'sprint';
  const sum = product.mode.value === 'kanban' ? 'nb_items' : 'evaluation';

  useEffect(() => {
    axios.get(`/products/${product.pk}/burnup?sum=${sum}&group_by=${groupBy}&limit=10`).then(res => {
      setData(res.data);
    }).catch(err => {
      setData(false);
      setError(t`Impossible to show graph`);
    });
  }, [product.pk, groupBy, sum]);

  return (
    <div className="graph graph-burnup">
      <h3 className="fs-5 text-center"><Trans>Product burnup</Trans></h3>
      <div className="graph__legend">
        <span className="bar bar-scope"></span> <Trans>Scope</Trans>
        <span className="bar bar-todo ms-3"></span> <Trans>Todo</Trans>
        <span className="bar bar-done ms-3"></span> <Trans>Done</Trans>
      </div>
      <div className="graph__inside">
        { data && (
          <div className="graph__graph">
            <SvgEl w="600" h="240" title={t`Scope`} data={data} max={data.max_value} />
          </div>
        ) }
        <div className="graph__yaxis">
          { sum === 'nb_items' ? (
            <Trans>Backlog items</Trans>
          ) : (
            product.estimate_mode.value === 'hours' ? (
              <Trans>Hours</Trans>
            ) : (
              <Trans>Points</Trans>
            )
          )}
        </div>
        <div className="graph__xaxis">
          { data && (
            groupBy === 'sprint' ? (
              <span>{data.labels[0]} - {data.labels[data.labels.length - 1]}</span>
            ) : (
              <>
                <DateFormat month="long" year="numeric" day="numeric" datestring={data.labels[0]}/> -{' '}
                <DateFormat month="long" year="numeric" day="numeric" datestring={data.labels[data.labels.length - 1]}/>
              </>
            )
          ) }
        </div>
        { error && (
          <div className="graph__error alert alert-danger">{ error }</div>
        ) }
      </div>

    </div>
  );
}
