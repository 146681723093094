import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert } from 'react-bootstrap';
import Datatable from 'components/datatable/Datatable';
import Icon from 'components/ui/Icon';
import AdminPage from 'components/page/AdminPage';
import AppContext from 'AppContext';
import OrganizationContext from 'components/utils/OrganizationContext';
import DateBlock from 'components/ui/DateBlock';
import MemberModal from 'components/modal/MemberModal';

function AddBtnBlock(props) {
  const { organization } = props;
  const cantAddMember = organization.is_members_limit_reached && organization.is_guests_limit_reached;

  return cantAddMember ? (
    <Alert variant="warning"><Trans>
      You cannot add members because the organization’s membership limit and
      the maximum guests number limit has been reached.</Trans></Alert>
  ) : (
    <Link className="btn btn-primary" to={`/${organization.slug}/manage/member/add`}>
      <Icon name="plus"/><Trans>Invite a new member</Trans>
    </Link>
  );

}
export default function MemberList() {
  const { user } = useContext(AppContext);
  const organization = useContext(OrganizationContext);
  const [showMember, setShowMember] = useState(null);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    t`Members`
  ];

  const showModal = item => {
    setShowMember(item);
  };

  return (
    <AdminPage
      name="member-list-page"
      title={t`Members`}
      breadcrumb={breadcrumb}>
      { organization.can_add_member && (
        <AddBtnBlock organization={organization} />
      ) }
      { showMember && (
        <MemberModal
          member={showMember}
          organization={organization}
          user={user}
          hideModal={() => { setShowMember(null); }} />
      ) }
      
      <Datatable
        api="/members"
        apiContext={{ organization: organization.pk }}
        canEdit={() => false}
        canDelete={item => organization.can_delete_member(item, user)}
        onDelete={() => organization.reload()}>
        <Datatable.Column name="resource_name" sort="user__full_name" label={t`Full name`}>
          {(item) => (
            <button
              type="button"
              className="btn-simplelink me-2"
              onClick={(e) => {
                e.preventDefault();
                showModal(item);
              }} >
              { item.resource_name }
            </button>
          )}
        </Datatable.Column>
        <Datatable.Column name="roles" label={t`Roles`}>
          { item => (
            <span className="badge bg-light me-1">{ item.role.label }</span>
          ) }
        </Datatable.Column>
        <Datatable.Column name="date_joined" label={t`Date joined`}>
          {(item) => (item.user.is_active ? (
            <DateBlock datestring={item.date_joined}/>
          ) : '')}
        </Datatable.Column>
        <Datatable.Column name="user__date_invited" label={t`Date invited`}>
          {(item) => (item.user.is_active ? '' : (
            <DateBlock datestring={item.user.date_invited}/>
          ))}
        </Datatable.Column>
      </Datatable>
    </AdminPage>
  );
}
