import React, { useState, useEffect, useContext, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Dropdown, Modal } from 'react-bootstrap';
import ProductContext from 'components/utils/ProductContext';
import { getOptionsFromAPI } from 'components/form/DropdownEditable';
import OrganizationContext from 'components/utils/OrganizationContext';
import axios from 'axios';
import { FormGlobalErrors, manageFormErrors } from 'components/form/Form';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import Product from 'entity/Product';
import SelectSearch from 'components/ui/SelectSearch';
import { ColorSticker } from 'components/ui/ColorSticker';
import Icon from 'components/ui/Icon';
import { getStateItem } from 'utils/Item';

function MatchList(props) {
  const { list, product, newProduct, formData, required, name, api } = props;
  const [optionsSource, setOptionsSource] = useState(null);
  const [optionsDest, setOptionsDest] = useState(null);
  const [values, setValues] = useState({});
  useEffect(() => {
    if (list === null) {
      getOptionsFromAPI(
        'prod-' + api + '-' + product.pk,
        '/' + api + '?mode=select&product=' + product.pk
      )().then(opts => {
        setOptionsSource(opts.filter(o => o.value !== null));
      });
    }
    else {
      setOptionsSource(list);
    }
    getOptionsFromAPI(
      'prod-' + api + '-' + newProduct.pk,
      '/' + api + '?mode=select&product=' + newProduct.pk
    )().then(opts => {
      setOptionsDest(required ? opts.filter(o => o.value !== null) : opts);
    });
  }, [list, product, newProduct, api, required]);

  useEffect(() => {
    if (optionsSource && optionsDest) {
      const vals = {};
      for (let i = 0; i < optionsSource.length; i++) {
        const val = optionsDest.find(o => o.label.localeCompare(optionsSource[i].label, undefined, { sensitivity: 'base' }) === 0);
        if (val) {
          vals[optionsSource[i].value] = val.value;
          formData.current[name][optionsSource[i].value] = {
            label: optionsSource[i].label,
            value: val.value
          };
        }
        else if (required && !(optionsSource[i].value in formData.current[name])) {
          formData.current[name][optionsSource[i].value] = {
            label: optionsSource[i].label,
            value: null
          };
        }
      }
      setValues(vals);
    }
  }, [optionsSource, optionsDest, formData, name, required, newProduct]);

  if (!optionsSource || !optionsDest) {
    return <LoaderContainer height="2" />;
  }

  const onClickItem = (e, opt, val, label) => {
    e.preventDefault();
    setValues(old => {
      old[opt] = val;
      formData.current[name][opt] = {
        label: label,
        value: val
      };
      return { ...old };
    });
  };
  return (
    <table className="table">
      <thead>
        <tr>
          <th>{ product.resource_name }</th>
          <td></td>
          <th>{ newProduct.resource_name }</th>
        </tr>
      </thead>
      <tbody>
        { optionsSource.map(opt => {
          const val = (opt.value in values) ? values[opt.value] : false;
          const selected = val !== false ? optionsDest.find(o => o.value === val) : null;
          return (
            <tr key={opt.value}>
              <td>
                <ColorSticker color={opt.item.color} /> { opt.label }
              </td>
              <td>
                <Icon name="arrow-right" />
              </td>
              <td data-cy={api + '-' + opt.value}>
                <Dropdown>
                  <Dropdown.Toggle className="btn" variant="outline-dark">
                    { (val === false || selected === null) ? (
                      <Trans>None</Trans>
                    ) : (
                      <>
                        {('item' in selected) && (
                          <ColorSticker color={selected.item.color} />
                        )}
                        {' '}{ selected.label }
                      </>
                    ) }
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { optionsDest.map(optDest => (
                      <Dropdown.Item
                        key={optDest.value}
                        as="button"
                        className={'btn ' + (val === optDest.value ? 'active' : 'btn-outline-secondary')}
                        onClick={e => onClickItem(e, opt.value, optDest.value, opt.label)}>
                        {('item' in optDest) && (
                          <ColorSticker color={optDest.item.color} />
                        )}
                        {' '}{ optDest.label }
                      </Dropdown.Item>
                    )) }
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          );
        }) }
      </tbody>
    </table>
  );
}
MatchList.defaultProps = {
  required: false
};

function BindingForm(props) {
  const { pks, product, newProduct, formData } = props;
  let check = 0;
  const item_types = [];
  const epics = [];
  for (let i = 0; i < pks.length; i++) {
    const item = getStateItem('backlog-items:' + pks[i], null);
    if (item) {
      check += 1;
      if (!item_types.find(itt => itt.value === item.item_type.pk)) {
        item_types.push({
          label: item.item_type.resource_name,
          value: item.item_type.pk,
          item: item.item_type
        });
      }
      if (item.epic && !epics.find(ep => ep.value === item.epic.pk)) {
        epics.push({
          label: item.epic.resource_name,
          value: item.epic.pk,
          item: item.epic
        });
      }
    }
  }
  return (
    <div className="relative">
      { newProduct && (
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="mb-2"><Trans>Item types</Trans></h4>
            <MatchList
              key={'item-types-' + newProduct.pk}
              list={check === pks.length ? item_types : null}
              name="itemTypes"
              api="item-types"
              formData={formData}
              product={product}
              newProduct={newProduct}
              required />
          </div>
          <div className="col-12 col-md-6">
            <h4 className="mb-2"><Trans>Epics</Trans></h4>
            <MatchList
              key={'epics-' + newProduct.pk}
              list={check === pks.length ? epics : null}
              name="epics"
              api="epics"
              formData={formData}
              product={product}
              newProduct={newProduct} />
          </div>
        </div>
      ) }
    </div>
  );
  
}
function BulkChangeProductModalContent(props) {
  const { pks, setShow, setSelectedPk } = props;
  const [loading, setLoading] = useState(false);
  const product = useContext(ProductContext);
  const organization = useContext(OrganizationContext);
  const [globalErrors, setGlobalErrors] = useState([]);
  const [newProduct, setNewProduct] = useState(null);
  const [productSel, setProductSel] = useState(null);
  const formData = useRef({
    itemTypes: {},
    epics: {},
    move_tag: null
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setGlobalErrors([]);
    const errors = [];
    const mapping = {
      item_type: [],
      epic: [],
    };

    for (const pk in formData.current.itemTypes) {
      if (formData.current.itemTypes[pk].value === null) {
        errors.push({
          key: 'error-item-type-' + pk,
          error: t`Binding for the item type «${formData.current.itemTypes[pk].label}» is missing.`
        });
      }
      else {
        mapping.item_type.push({
          source: parseInt(pk, 10),
          destination: formData.current.itemTypes[pk].value
        });
      }
    }
    for (const pk in formData.current.epics) {
      mapping.epic.push({
        source: parseInt(pk, 10),
        destination: formData.current.epics[pk].value
      });
    }
    const data = {
      pks: pks,
      mapping: mapping
    };
    if (formData.current.move_tag !== null && formData.current.move_tag.length > 0) {
      data.fields = {
        move_tag: formData.current.move_tag
      };
    }

    if (errors.length > 0) {
      setGlobalErrors(errors);
    }
    else {
      setLoading(true);
      axios.put('/backlog-items/bulk/product/' + productSel.pk, data).then(res => {
        setLoading(false);
        if ('job' in res.data) {
          if (setSelectedPk) setSelectedPk([]);
          setShow(false);
          addMessage('bulk-item-created', t`Success`, res.data.message);
        }
        else {
          setGlobalErrors([{ key: 'error-message', error: res.data.message }]);
        }
      })
        .catch(err => {
          console.error(err);
          setLoading(false);
          const errs = manageFormErrors(null, err);
          setGlobalErrors(errs.globalErrors);
        });
    }
  };

  const setTag = (value) => {
    formData.current.move_tag = value;
  };

  useEffect(() => {
    if (productSel !== null) {
      setLoading(true);
      setGlobalErrors([]);
      const prod = new Product();
      formData.current = {
        itemTypes: {},
        epics: {},
        move_tag: null
      };
      prod.load(
        { organizationSlug: organization.slug,
          productSlug: productSel.resource_slug }).then((response) => {
        setLoading(false);
        if (!prod.can_edit_item) {
          setGlobalErrors([{
            key: 'product-load',
            error: t`You don't have the permission to add items in this product`
          }]);
        }
        else {
          setNewProduct(prod);
        }
        
      }).catch((err) => {
        setGlobalErrors([{ key: 'product-load', error: err.response || err.message }]);
      });
    }
    
  }, [productSel, organization.slug]);

  return (
    <form className="relative" method="PUT" action="" onSubmit={onSubmit}>
      <div className="form-group mb-3">
        <div className="form-label d-inline-block me-3">
          <Trans>Product</Trans>
        </div>
        <SelectSearch
          handleSelect={option => { setProductSel(option); }}
          filter={options => options.filter(o => o.pk !== product.pk)}
          required
          searchApi={`/products?organization=${organization.pk}`} />
      </div>
      
      { loading && <LoaderContainer /> }
      
      <FormGlobalErrors errors={globalErrors} />
      { newProduct && (
        <>
          <hr />
          <BindingForm pks={pks} formData={formData} product={product} newProduct={newProduct} />
          <div className="form-group form-group-tag">
            <label htmlFor="move-tag"><Trans>Add tag</Trans></label>
            <input
              onChange={(e) => setTag(e.target.value)}
              defaultValue={formData.current.move_tag || ''}
              className="form-control"
              type="text"
              id="move-tag"
              name="move-tag" />
            <div className="text-muted">
              <Trans>You can add a tag to moved items</Trans>
            </div>
          </div>
          
          <input type="submit" className="btn btn-primary" value={t`Move ${pks.length} items`} />
        </>
      ) }
    </form>    
  );
}

function BulkChangeProductModal(props) {
  const { pks, dropdownItem, setSelectedPk } = props;
  const [show, setShow] = useState(false);
  if (pks.length < 1) {
    return null;
  }

  return (
    <>
      <button type="button" className={'btn btn-outline-dark' + (dropdownItem ? ' dropdown-item' : '')} onClick={() => { setShow(true); }}>
        <Trans>Change product</Trans>
        {!dropdownItem && (
          <span className="badge text-bg-secondary ms-2">{ pks.length }</span>
        )}
      </button>
      <Modal
        enforceFocus={false}
        size="xl"
        show={show}
        onHide={() => { setShow(false); }}
        className="bulk-change-product-modal"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="backlog-title">
            <Trans>Change product</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BulkChangeProductModalContent setShow={setShow} pks={pks} setSelectedPk={setSelectedPk} />
        </Modal.Body>
      </Modal>
      
    </>
  );
}
BulkChangeProductModal.defaultProps = {
  dropdownItem: false
};

export default BulkChangeProductModal;
