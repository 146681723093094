import React, { useState, useEffect, useContext, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, Modal } from 'react-bootstrap';
import ProductContext from 'components/utils/ProductContext';
import SelectSearch from 'components/ui/SelectSearch';
import { getOptionsFromAPI } from 'components/form/DropdownEditable';
import Autocomplete from 'components/ui/Autocomplete';
import OrganizationContext from 'components/utils/OrganizationContext';
import axios from 'axios';
import { FormGlobalErrors, manageFormErrors } from 'components/form/Form';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';

function getValue(data) {
  if (data.value === null) return t`Null`;
  switch (typeof data.value) {
    case 'string':
    case 'number':
      return data.value;
    case 'boolean':
      return data.value ? t`True` : t`False`;
    case 'object':
      if ('resource_name' in data.value) return data.value.resource_name;
      if ('label' in data.value) return data.value.label;
      if (Array.isArray(data.value)) {
        if (data.value.length < 1) return t`Empty`;
        return data.value.map(v => getValue({ value: v })).join(', ');
      }
      return t`Undefined`;
    default:
      return t`Undefined`;
  }
}
function BulkItemAutocomplete(props) {
  const { item, setFormData } = props;
  const [values, setValues] = useState({
    value: [],
    option: 'replace'
  });
  const setOpt = e => {
    setValues(old => ({
      value: old.value,
      option: e.target.value
    }));
  };
  useEffect(() => {
    setFormData(old => {
      old[item.name].value = values.value;
      old[item.name].option = values.option;
      return { ...old };
    });
  }, [values, item.name, setFormData]);
  return (
    <div className="form-group">
      <label className="form-label visually-hidden" htmlFor={'field-val-' + item.name}>{ item.label }</label>
      <div className="form-value">
        <Autocomplete
          searchApi={item.api}
          onChange={items => {
            const newVals = item.name === 'tags' ? items.map(it => it.pk) : items;
            setValues(old => {
              old.value = newVals;
              return { ...old };
            });
          }}
          id={'field-val-' + item.name}
          inputLabel={t`Search`}
          autocreate={'autocreate' in item && item.autocreate}>
        </Autocomplete>
        <Form.Check
          inline
          label={t`replace`}
          name={'field-opt-' + item.name}
          type="radio"
          id={'field-opt-replace-' + item.name}
          value="replace"
          checked={values.option === 'replace'}
          onChange={setOpt}
        />
        <Form.Check
          inline
          label={t`append`}
          name={'field-opt-' + item.name}
          type="radio"
          id={'field-opt-append-' + item.name}
          value="append"
          checked={values.option === 'append'}
          onChange={setOpt}
        />
      </div>
    </div>
  );
}

function BulkItemInput(props) {
  const { item, setFormData, fieldsErrors } = props;
  const [value, setValue] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    setFormData(old => {
      old[item.name].value = value;
      return { ...old };
    });
  }, [value, item.name, setFormData]);

  const classes = ['form-control'];
  if (fieldsErrors[item.name]) classes.push('is-invalid');
  return (
    <div className="form-group">
      <label className="form-label visually-hidden" htmlFor={'field-val-' + item.name}>{ item.label }</label>
      <div className="form-value input-group">
        <input
          ref={inputRef}
          type={('type' in item) ? item.type : 'text'}
          id={'field-val-' + item.name}
          className={classes.join(' ')}
          onInput={(e) => {
            const val = e.target.value.trim().length < 1 ? null : e.target.value.trim();
            setValue(val);
          }} />
        { value && (
          <button type="button" className="btn btn-outline-dark" onClick={() => { setValue(null); inputRef.current.value = ''; }}>
            <Trans>Clear</Trans>
          </button>
        ) }
        
      </div>
      { fieldsErrors[item.name] && (
        <div className="invalid-feedback d-block">
          { fieldsErrors[item.name] }
        </div>
      ) } 
    </div>
  );
}

function BulkItemCheckbox(props) {
  const { item, data, setFormData } = props;
  return (
    <div className="form-group">
      <Form.Check
        name={'field-val-' + item.name}
        id={'field-val-' + item.name}
        label={item.label}
        checked={'value' in data && data.value}
        onChange={e => {
          setFormData(old => {
            old[item.name].value = e.target.checked;
            return { ...old };
          });
        }} />
    </div>
  );
}
function BulkItemMany2One(props) {
  const { item, data, setFormData } = props;
  return (
    <div className="form-group">
      <label className="form-label visually-hidden" htmlFor={'field-val-' + item.name}>{ item.label }</label>
      <div className="form-value">
        <SelectSearch
          handleSelect={option => setFormData(old => {
            old[item.name].value = option.pk === null ? null : option;
            return { ...old };
          })}
          onOptionsChange={opts => {
            if (!('value' in data) && !(('required' in item) && item.required)) {
              setFormData(old => {
                const option = opts[0];
                old[item.name].value = option.pk === null ? null : option;
                return { ...old };
              });
            }
          }}
          required={('required' in item) && item.required}
          searchApi={item.api} />
      </div>
    </div>
  );
}
function BulkItemSelect(props) {
  const { item, data, setFormData } = props;

  useEffect(() => {
    if (!('value' in data)) {
      setFormData(old => {
        old[item.name].value = item.options[0].value;
        return { ...old };
      });
    }
  }, [item, data, setFormData]);
  return (
    <div className="form-group">
      <label className="form-label visually-hidden" htmlFor={'field-val-' + item.name}>{ item.label }</label>
      <div className="form-value">
        <select
          id={'field-val-' + item.name}
          className="form-select d-inline-block"
          onInput={(e) => {
            setFormData(old => {
              old[item.name].value = e.target.value;
              return { ...old };
            });
          }}>
          { item.options.map(opt => (
            <option key={opt.label} value={opt.value}>{ opt.label }</option>
          )) }
        </select>
      </div>
    </div>
  );
}
function BulkItem(props) {
  const { item, formData, setFormData, fieldsErrors } = props;
  const data = (item.name in formData) ? formData[item.name] : { active: false };
  return (
    <tr>
      <td>
        <Form.Check
          name={'update-' + item.name}
          id={'update-' + item.name}
          label={item.label}
          checked={data.active}
          onChange={e => {
            setFormData(old => {
              if (e.target.checked) {
                old[item.name] = { active: true };
                return { ...old };
              }
              delete old[item.name];
              return { ...old };
            });
          }} />
      </td>
      <td>
        { data.active && ('Field' in item) && (
          <item.Field data={data} fieldsErrors={fieldsErrors} setFormData={setFormData} item={item} />
        ) }
      </td>
      <td>
        {'value' in data ? (
          <span className="text-success">
            { getValue(data) }
          </span>
        ) : (
          <span className="text-muted"><Trans>No changes</Trans></span>
        )}
      </td>
    </tr>
  );
}

function BulkItemsModalContent(props) {
  const { pks, setShow } = props;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [priorityOptions, setPriorityOptions] = useState([]);
  const product = useContext(ProductContext);
  const organization = useContext(OrganizationContext);
  const [fieldsErrors, setFieldsErrors] = useState({});
  const [globalErrors, setGlobalErrors] = useState([]);
  const [disableNotifications, setDisableNotifications] = useState(false);

  const estimateField = product.estimate_mode.value === 'hours' ? {
    name: 'hours_estimate', label: t`Hours estimate`, Field: BulkItemInput
  } : {
    name: 'points_estimate', label: t`Points estimate`, Field: BulkItemInput
  };
  const fields = [
    {
      name: 'default',
      label: null,
      items: [
        { name: 'status', label: t`Status`, Field: BulkItemMany2One, api: '/item-status?product=' + product.pk, required: true },
        { name: 'fix_versions', label: t`Fix versions`, Field: BulkItemAutocomplete, api: '/releases?product=' + product.pk },
        {
          name: 'affects_versions',
          label: t`Affects versions`,
          Field: BulkItemAutocomplete,
          api: '/releases?product=' + product.pk
        }
      ]
    },
    {
      name: 'category',
      label: t`Category`,
      items: [
        { name: 'item_type', label: t`Type`, Field: BulkItemMany2One, api: '/item-types?product=' + product.pk, required: true },
        { name: 'epic', label: t`Epic`, Field: BulkItemMany2One, api: '/epics?product=' + product.pk },
        { name: 'tags', label: t`Tags`, Field: BulkItemAutocomplete, api: '/tags/' + organization.slug, autocreate: true },
        {
          name: 'priority',
          label: t`Priority`,
          Field: BulkItemSelect,
          options: priorityOptions,
          required: true
        }
      ]
    },
    {
      name: 'people',
      label: t`People`,
      items: [
        { name: 'assignee', label: t`Assignee`, Field: BulkItemMany2One, api: '/members?organization=' + organization.pk },
        { name: 'reporter', label: t`Reporter`, Field: BulkItemMany2One, api: '/members?organization=' + organization.pk }
      ]
    },
    {
      name: 'work',
      label: t`Work`,
      items: [
        estimateField,
        { name: 'business_value', label: t`Business value`, Field: BulkItemInput, type: 'number' },
        { name: 'need_estimate', label: t`Need estimate`, Field: BulkItemCheckbox },
        { name: 'deadline', label: t`Deadline`, Field: BulkItemInput, type: 'date' },
        { name: 'default_account', label: t`Default account`, Field: BulkItemMany2One, api: '/accounts?product=' + product.pk }
      ]
    },    

  ];

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    for (const f in formData) {
      if (formData[f].active) {
        if ('option' in formData[f]) {
          data[f] = {
            mode: formData[f].option,
            values: formData[f].value,
          };
        }
        else {
          data[f] = formData[f].value;
        }
        
      }
    }
    const fieldsNames = [];
    for (const f in fields) {
      for (const i in fields[f].items) {
        fieldsNames.push(fields[f].items[i].name);
      }
    }
    axios.put('/backlog-items/bulk', {
      pks: pks,
      fields: data,
      disable_notifications: disableNotifications
    })
      .then(res => {
        setLoading(false);
        if ('job' in res.data) {
          setShow(false);
          addMessage('bulk-item-created', t`Success`, res.data.message);
        }
        else {
          setGlobalErrors([{ key: 'error-message', error: res.data.message }]);
        }
        
      })
      .catch(err => {
        setLoading(false);
        const errors = manageFormErrors(fieldsNames, err);
        setFieldsErrors(errors.fieldsErrors);
        setGlobalErrors(errors.globalErrors);
      });
  };

  useEffect(() => {
    getOptionsFromAPI('backlog-priority-options', '/backlog-items/meta', 'priority')().then(opts => {
      setPriorityOptions(opts.filter(o => o.value !== null));
    });
  }, []);

  return (
    <form className="relative" method="PUT" action="" onSubmit={onSubmit}>
      { loading && <LoaderContainer /> }
      <table className="table">
        <thead>
          <tr>
            <th><Trans>Field</Trans></th>
            <th className="table--settings"><Trans>Settings</Trans></th>
            <th><Trans>New value</Trans></th>
          </tr>
        </thead>
        <tbody>
          { fields.map(group => (
            <React.Fragment key={group.name}>
              { group.label && (
                <tr className="table-secondary">
                  <th colSpan="3">{ group.label }</th>
                </tr> 
              ) }
              { group.items.map(item => (
                <BulkItem key={item.name} fieldsErrors={fieldsErrors} item={item} formData={formData} setFormData={setFormData} />
              )) }
            </React.Fragment>
          )) }
        </tbody>
      </table>
      <FormGlobalErrors errors={globalErrors} />
      <div className="form-group pt-2">
        <input type="submit" className="btn btn-primary me-3" value={t`Submit`} />
        <Form.Check
          inline
          label={t`Disable notifications`}
          name="bulk-disable-notifications"
          type="checkbox"
          id="bulk-disable-notifications"
          value="replace"
          checked={disableNotifications}
          onChange={(e) => {
            setDisableNotifications(e.target.checked);
          }}
        />
        
      </div>
      
    </form>    
  );
}

function BulkItemsModal(props) {
  const { pks, dropdownItem } = props;
  const [show, setShow] = useState(false);
  if (pks.length < 1) {
    return null;
  }

  return (
    <>
      <button type="button" className={'btn btn-outline-dark' + (dropdownItem ? ' dropdown-item' : '')} onClick={() => { setShow(true); }}>
        <Trans>Update items</Trans>
        {!dropdownItem && (
          <span className="badge text-bg-secondary ms-2">{ pks.length }</span>
        )}
      </button>
      <Modal
        enforceFocus={false}
        size="xl"
        show={show}
        onHide={() => { setShow(false); }}
        className="bulk-items-modal"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="backlog-title">
            <Trans>Update {pks.length} items</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BulkItemsModalContent setShow={setShow} pks={pks} />
        </Modal.Body>
      </Modal>
      
    </>
  );
}
BulkItemsModal.defaultProps = {
  dropdownItem: false
};

export default BulkItemsModal;
