import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppContext from 'AppContext';
import ProductPage from 'components/page/ProductPage';
import { LoaderContainer } from 'components/ui/Loader';
import ProductContext from 'components/utils/ProductContext';
import { Form, FormContext, FormContextProvider } from 'components/form';
import DateBlock from 'components/ui/DateBlock';
import { ConfirmButton } from 'components/utils';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import OrganizationContext from 'components/utils/OrganizationContext';
import DropdownEditable, { getOptionsFromAPI } from 'components/form/DropdownEditable';
import { setStateItem } from 'utils/Item';

function MemberForm(props) {
  const { product } = props;
  const { item } = useContext(FormContext);
  const [refreshRole, setRefreshRole] = useState(0);

  item.product = product;

  const handleMemberSelect = member => {
    if (member && member.role_guest) {
      item.role = 'role_stakeholder';
    } else {
      item.role = null;
    }
    setRefreshRole(refreshRole + 1);
  };

  return (
    <div className="d-block d-md-flex">
      <div>
        <Form.Many2One
          name="member"
          api={`/members?organization=${product.organization.pk}`}
          label={t`Member`}
          required
          onSelectValue={handleMemberSelect}/>
      </div>
      {item.member && (
        <>
          <div className="ms-md-4">
            { item.member.role_guest ? (
              <>
                <div className="form-label"><Trans>Role</Trans></div>
                <Trans>Stakeholder</Trans>
              </>
            ) : (
              <Form.Select key={refreshRole} id="role" name="role" label={t`Role`} required />
            ) }
          </div>
          <div className="ms-md-4 mt-md-4 pt-md-2">
            <Button variant="primary" type="submit"><Trans>Add</Trans></Button>
          </div>
        </>
      )}
    </div>
  );
}

function MemberRow(props) {
  const { product, member, user, onDelete, hasInactive, roleOptions } = props;
  const [, setRefresh] = useState(0);

  const onSendInvitation = () => {
    axios.put(`/members/${member.member.pk}/send-invitation-email`)
      .then((response) => {
        member.member.user.date_invited = response.data.user.date_invited;
        addMessage(
          'send-invitation-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'send-invitation-error', t`Unknown error`, error.response.data.message));
  };

  return (
    <tr className={member.member.user.is_active ? '' : 'text-muted'}>
      <td data-label={t`Member`}>{member.member.resource_name}</td>
      <td data-label={t`Role`}>
        {product.can_edit_team_member(member, user) && !(member.member.role === 'role_guest') ? (
          <DropdownEditable
            pk={member.pk}
            api="team-members"
            getOptions={() => new Promise((resolve, reject) => {
              resolve(roleOptions);
            })}
            required
            variant="light rounded-pill"
            field="role"
            fieldName={t`Role`}
            fieldValue="value"
            fieldLabel="label" />
        ) : (member.role ? member.role.label : '')}
      </td>
      <td data-label={t`Date added`}><DateBlock datestring={member.date_joined}/></td>
      { hasInactive && (
        <td data-label={t`Date invited`}>
          {!member.member.user.is_active && member.member.user.date_invited && (
            <DateBlock datestring={member.member.user.date_invited}/>
          ) }
        </td>
      ) }
      <td className="text-end col__actions">
        {!member.member.user.is_active && product.can_edit_team_member(member, user) && (
          <button
            type="button"
            className="btn btn-outline-secondary me-2"
            onClick={onSendInvitation}>
            <Trans>Resend invitation</Trans> <Icon name="envelope"/>
          </button>
        )}
        {product.can_delete_team_member(member, user) && (
          <ConfirmButton
            message={<Trans>Are you sure you want to delete this element?</Trans>}
            variant="outline-secondary"
            onConfirm={() => onDelete(member)}>
            <Trans>Remove</Trans> <Icon name="trash"/>
          </ConfirmButton>
        )}
      </td>
    </tr>
  );
}

function StakeholderForm(props) {
  const { onSave } = props;
  const [state, setState] = useState(1);

  const saveHandler = () => {
    onSave();
    setState(prev => prev + 1);
  };
  return (
    <Form
      name="member-form"
      onSave={saveHandler}>
      <div className="d-flex align-items-start">
        <Form.CustomField
          key={state}
          containerClassName="flex-shrink-1"
          className="w-100"
          type="email"
          id="stakeholder-email"
          name="email"
          label={t`E-mail`}
          required />
        <div className="mt-4 pt-2 ms-2 flex-shrink-1">
          <button type="submit" className="btn btn-outline-secondary">
            <Trans>Invite</Trans>
          </button>
        </div>
      </div>
    </Form>
  );
}

function ProductTeamForm(props) {
  const { product } = props;
  const { user, lang } = useContext(AppContext);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [roleOptions, setRoleOptions] = useState(null);
  const [memberFormVersion, setMemberFormVersion] = useState(0);
  const organization = useContext(OrganizationContext);

  const loadMembers = useCallback(() => {
    setIsLoading(true);
    axios.get('/team-members?product=' + product.pk).then((result) => {
      for (const member of result.data.items) {
        setStateItem('team-members:' + member.pk, member);
      }
      setMembers(result.data.items);
      setIsLoading(false);
    }).catch(function (err) {
      addMessage(
        'team-member-reload-failed',
        t`Unknown error`,
        t`Impossible to load members.`
      );
    });
  }, [product]);

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);
  
  useEffect(() => {
    getOptionsFromAPI('team-member-roles-options-' + lang.pk, '/team-members/meta', 'role')().then(opts => {
      setRoleOptions(opts);
    });
  }, [lang.pk]);

  const onDelete = member => {
    axios.delete(`/team-members/${member.pk}`).then((result) => {
      if (product.product_owner && member.pk === product.product_owner.pk) {
        product.product_owner = null;
      }
      if (product.scrum_master && member.pk === product.scrum_master.pk) {
        product.scrum_master = null;
      }
      setMembers(members.filter(m => m.pk !== member.pk));
    }).catch(function (err) {
      addMessage(
        'team-member-delete-failed',
        t`Unknown error`,
        t`Impossible to delete member.`
      );
    });
  };

  const getMembersOptions = useCallback(() => new Promise(resolve => {
    const opts = [{
      label: t`None`,
      value: null
    }];
    for (const member of members.filter(m => !m.member.role_guest)) {
      opts.push({
        label: member.resource_name,
        value: member.pk,
        item: member
      });
    }
    resolve(opts);
  }), [members]);

  const hasInactive = members.filter(m => !m.member.user.is_active).length > 0;
  return (
    <>
      <div className="row">
        <div className="mb-3 col-sm-6 d-flex">
          <h5 className="my-auto me-4"><Trans>Product owner</Trans></h5>
          <div>
            <Icon name="person-circle"/>{' '}
            { product.can_edit ? (
              <DropdownEditable
                stateKey={product._getStateKey()}
                pk={product.pk}
                api={product._genericApi}
                getOptions={getMembersOptions}
                variant="light rounded-pill"
                field="product_owner"
                fieldName={t`Product owner`}
                fieldValue="pk"
                fieldLabel="resource_name" />
            ) : (product.product_owner ? product.product_owner.resource_name : t`None`) }
          </div>
        </div>
        <div className="mb-3 col-sm-6 d-flex">
          <h5 className="my-auto me-4"><Trans>Scrum master</Trans></h5>
          <div>
            <Icon name="person-circle"/>{' '}
            { product.can_edit ? (
              <DropdownEditable
                onUpdate={() => {}}
                stateKey={product._getStateKey()}
                pk={product.pk}
                api={product._genericApi}
                getOptions={getMembersOptions}
                variant="light rounded-pill"
                field="scrum_master"
                fieldName={t`Scrum master`}
                fieldValue="pk"
                fieldLabel="resource_name" />
            ) : (product.scrum_master ? product.scrum_master.resource_name : t`None`) }
          </div>
        </div>
      </div>
      <hr/>
      <div className="relative">
        { (isLoading || !roleOptions) ? (
          <LoaderContainer/>
        ) : (
          <table className="table table-responsive align-middle">
            <thead>
              <tr>
                <th><Trans>Member</Trans></th>
                <th><Trans>Role</Trans></th>
                <th><Trans>Date added</Trans></th>
                { hasInactive && (
                  <th><Trans>Date invited</Trans></th>
                ) }
                <th className="text-end"><Trans>Actions</Trans></th>
              </tr>
            </thead>
            <tbody>
              {members && members.map(member => (
                <MemberRow
                  key={member.pk}
                  product={product}
                  member={member}
                  user={user}
                  hasInactive={hasInactive}
                  roleOptions={roleOptions}
                  onDelete={onDelete}/>
              ))}
            </tbody>
          </table>
        ) }
        
        {product.can_add_team_member && (
          <div className="row align-items-stretch">
            <div className="col-md-8 mb-3 mb-md-0">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title"><Trans>Add a team member</Trans></h5>
                  <FormContextProvider api="/team-members" key={'member-form-' + memberFormVersion}>
                    <Form
                      id="team-member-form"
                      onSave={() => {
                        setMemberFormVersion(v => v + 1);
                        loadMembers();
                      }}
                      noValidate>
                      <MemberForm product={product}/>
                    </Form>
                  </FormContextProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title"><Trans>Invite stakeholder</Trans></h5>
                  {product.can_add_team_member ? (
                    organization.is_guests_limit_reached ? (
                      <div className="alert alert-warning">
                        <Trans>The maximum number of allowed guests is reached for the organization.</Trans>
                      </div>
                    ) : (
                      <FormContextProvider
                        item={{ product: product.pk }}
                        api="/team-members/invite-stakeholder">
                        <StakeholderForm onSave={() => {
                          organization.reload();
                          loadMembers();
                        }}/>
                      </FormContextProvider>
                    )
                  ) : (
                    <div className="alert alert-warning">
                      <Trans>You must be a manager of the organization to invite new members.</Trans>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default function ProductTeamPage() {
  const product = useContext(ProductContext);
  const organization = useContext(OrganizationContext);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Team`
  ];
  return (
    <ProductPage
      name="product-team-page"
      title={t`Team`}
      breadcrumb={breadcrumb}>
      <ProductTeamForm product={product} organization={organization}/>
    </ProductPage>
  );
}
