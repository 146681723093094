import axios from 'axios';
import { modelsApi, statusNames } from 'components/utils/constants';
import Entity from 'entity/Entity';

export default class BacklogItem extends Entity {
  constructor() {
    super(['pk'], modelsApi.BacklogItem + '/:pk', modelsApi.BacklogItem, [
      'pk',
      'reference',
      'parent',
      'item_type',
      'title',
      'description',
      'status',
      'assignee',
      'reporter',
      'product',
      'order',
      'hours_estimate',
      'points_estimate',
      'priority',
      'business_value',
      'default_account'
    ]);
  }

  load_by_reference(organization_slug, reference) {
    this._url = `${this._genericApi}/reference/${organization_slug}/${reference}`;
    return new Promise((resolve, reject) => {
      axios.get(this._url).then((response) => {
        this.loadFromObject(response.data, null, true);
        resolve(response);
      }).catch((error) => { reject(error); });
    });
  }

  move(status, action, target, sprint, assign_me) {
    const entity = {};
    const params = {};
    if (status) entity.status = status;
    if (action) {
      params.move = action;
      if (target) params.object = target;
    }
    if (assign_me) params.assign_me = assign_me;
    if (typeof sprint !== 'undefined') {
      entity.sprint = sprint;
    }
    const queryString = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      axios.put(`${this._genericApi}/${this.pk}?${queryString}`, entity).then((response) => {
        if (status) {
          this.status = {
            value: status,
            label: statusNames[status]
          };
        }
        resolve(response);
      }).catch((error) => { reject(error); });
    });
  }

  isFullyEstimated(type) {
    return type === 'hours' ? this.has_hours_estimate : this.has_points_estimate;
  }

  hasEstimate(type) {
    if (type === 'hours') {
      return !!(this.hours_estimate && this.hours_estimate.minutes !== 0);
    }
    return !!this.points_estimate;
  }

  getEstimate(type) {
    if (type === 'hours') {
      return this.hours_estimate ? this.hours_estimate.human_format : '';
    }
    return Math.round(this.points_estimate * 100) / 100;
  }
}
