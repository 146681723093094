import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import Kanban, { KanbanCol } from 'components/product/kanban/Kanban';
import ContentBlock from 'components/content/ContentBlock';
import { statusNames } from 'components/utils/constants';
import { LoaderContainer } from 'components/ui/Loader';
import useIsMobile from 'utils/isMobile';
import { AddSimpleBacklogForm, ResizableCol } from './SprintBacklogPage';

function ColsFilters(props) {
  const { preBacklogStatus, filters, filterChoose } = props;

  return (
    <div className="content-block mb-2 p-2">
      <span className="me-3"><Trans>Show columns:</Trans></span>
      <div className="btn-group" role="group">
        <button
          type="button"
          className={'btn ' + (('filter-add' in filters && !filters['filter-add']) ? 'btn-outline-secondary' : 'btn-primary')}
          onClick={() => { filterChoose('filter-add', false); }}>
          <Trans>Form</Trans>
        </button>
        { preBacklogStatus.map(status => (
          <button
            key={status.pk}
            type="button"
            className={'btn ' +
              ((('filter-' + status.pk) in filters && filters['filter-' + status.pk]) ? 'btn-primary' : 'btn-outline-secondary')}
            onClick={() => { filterChoose('filter-' + status.pk, true); }}>
            {status.resource_name}
          </button>
        ))}
      </div>
    </div>
  );
}

function BacklogPageContent(props) {
  const isMobile = useIsMobile(768);
  if (isMobile) {
    return <BacklogPageMobile {...props} />;
  }
  return <BacklogPageDesktop {...props} />;
}

function BacklogPageDesktop(props) {
  const { product } = props;

  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(false);
  const oldFilters = localStorage.getItem('backlog-' + product.pk + '-filters');
  const [filters, setFilters] = useState(oldFilters ? JSON.parse(oldFilters) : {});
  const filterChoose = (name, defaultValue) => {
    const newFilters = { ...filters };
    newFilters[name] = (name in newFilters) ? !newFilters[name] : defaultValue;
    localStorage.setItem('backlog-' + product.pk + '-filters', JSON.stringify(newFilters));
    setFilters(newFilters);
    setRefresh(r => r + 1);
  };
  const itemOptions = {};
  const preBacklogStatus = product.statuses.filter(s => s.status_type.value === 'pre_backlog');
  const showFilters = (product.mode.value === 'kanban' && preBacklogStatus.length > 1) ||
    (product.mode.value === 'scrum' && preBacklogStatus.length > 2);
  const cols = [];
  for (let i = 0; i < preBacklogStatus.length; i++) {
    const showCol = !showFilters || (('filter-' + preBacklogStatus[i].pk) in filters && filters['filter-' + preBacklogStatus[i].pk]);
    cols.push(
      <KanbanCol hideCol={!showCol} key={'pk' + preBacklogStatus[i].pk} id={preBacklogStatus[i].resource_name} status={preBacklogStatus[i]}>
        { preBacklogStatus[i].resource_name }
      </KanbanCol>
    );
  }
  cols.push(
    <KanbanCol key="1" id="backlog" status={product.statuses.find(s => s.status_type.value === 'backlog')}>
      { statusNames.backlog }
    </KanbanCol>
  );
  if (product.mode.value === 'kanban') {
    cols.push(
      <KanbanCol key="2" id="todo" status={product.statuses.find(s => s.status_type.value === 'todo')}>
        { statusNames.todo }
      </KanbanCol>
    );
    itemOptions.params = 'subtasks=-children';
  } else {
    itemOptions.params = 'sprint=null&subtasks=-children&' +
      product.getStatusParams(['backlog, pre_backlog']);
  }

  const showResizable = !showFilters || !('filter-add' in filters) || filters['filter-add'];

  return (
    <>
      { showFilters && (
        <ColsFilters preBacklogStatus={preBacklogStatus} filters={filters} filterChoose={filterChoose} />
      ) }

      <div className="d-flex">
        {product.can_add_item && showResizable && (
          <ResizableCol hideBtn={showFilters}>
            <ContentBlock
              className="p-3">
              <AddSimpleBacklogForm
                statuses={product.statuses.filter(s => s.status_type.value === 'backlog' || s.status_type.value === 'pre_backlog')}
                product={product}
                setRefresh={setRefresh}
                setLoading={setLoading} />
            </ContentBlock>
          </ResizableCol>
        )}
        <div className="flex-grow-1 relative">
          {loading && (
            <LoaderContainer/>
          )}
          <Kanban
            product={product}
            refresh={refresh}
            itemsOptions={itemOptions}
            readonly={!product.can_edit_item}
            layout="backlog"
            responsive={false}>
            { cols }
          </Kanban>
        </div>
      </div>
    </>
  );
}

function BacklogPageMobile(props) {
  const { product } = props;

  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(false);
  const statusTypes = ['pre_backlog', 'backlog'];
  if (product.mode.value === 'kanban') {
    statusTypes.push('todo');
  }
  const preBacklogStatus = product.statuses.filter(s => statusTypes.includes(s.status_type.value));
  const [filter, setFilter] = useState('filter-' + preBacklogStatus.find(s => s.status_type.value === 'backlog').pk);
  const itemOptions = {};

  const activeStatus = preBacklogStatus.find(s => filter === 'filter-' + s.pk);
  if (product.mode.value === 'kanban') {
    itemOptions.params = 'subtasks=-children';
  } else {
    itemOptions.params = 'sprint=null&subtasks=-children&' +
      product.getStatusParams(['backlog, pre_backlog']);
  }
  useEffect(() => {
    setRefresh(r => r + 1);
  }, [filter]);
  return (
    <>
      <div className="content-block mb-2 p-2">
        { preBacklogStatus.length > 2 && product.can_add_item || preBacklogStatus > 3 && !product.can_add_item ? (
          <div className="btn-group-status btn-group-status-dropdown">
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary">
                { filter === 'c-filter-add' ? (
                  <Trans>Form</Trans>
                ) : (
                  activeStatus.resource_name
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                { product.can_add_item && (
                  <Dropdown.Item
                    as="button"
                    key="c-filter-add"
                    className={'btn btn-outline-secondary' + (filter === 'c-filter-add' ? ' active' : '')}
                    onClick={() => setFilter('c-filter-add')}>
                    <Trans>Form</Trans>
                  </Dropdown.Item>
                )}
                { preBacklogStatus.map(status => (
                  <Dropdown.Item
                    as="button"
                    key={status.pk}
                    className={'btn btn-outline-secondary' + (filter === ('filter-' + status.pk) ? ' active' : '')}
                    onClick={() => setFilter('filter-' + status.pk)}>
                    {status.resource_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className="btn-group btn-group-status btn-group-status-buttons" role="group">
            { product.can_add_item && (
              <button
                type="button"
                className={'btn ' + (filter === 'c-filter-add' ? 'btn-primary' : 'btn-outline-secondary')}
                onClick={() => { setFilter('c-filter-add'); }}>
                <Trans>Form</Trans>
              </button>
            )}

            { preBacklogStatus.map(status => (
              <button
                key={status.pk}
                type="button"
                className={'btn ' +
                  (filter === 'filter-' + status.pk ? 'btn-primary' : 'btn-outline-secondary')}
                onClick={() => { setFilter('filter-' + status.pk); }}>
                {status.resource_name}
              </button>
            ))}
          </div>
        )}

      </div>

      { filter === 'c-filter-add' && product.can_add_item && (
        <ContentBlock
          className="p-3">
          <AddSimpleBacklogForm
            statuses={product.statuses.filter(s => s.status_type.value === 'backlog' || s.status_type.value === 'pre_backlog')}
            product={product}
            setRefresh={setRefresh}
            setLoading={setLoading} />
        </ContentBlock>
      )}
      { activeStatus && (
        <>
          {loading && (
            <LoaderContainer/>
          )}
          <Kanban
            product={product}
            refresh={refresh}
            itemsOptions={itemOptions}
            readonly={!product.can_edit_item}
            layout="backlog"
            responsive={false}>
            <KanbanCol key={'pk' + activeStatus.pk} id={activeStatus.resource_name} status={activeStatus}>
              { activeStatus.resource_name }
            </KanbanCol>
          </Kanban>

        </>
      ) }
    </>
  );
}
export default function BacklogPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Product Backlog`
  ];

  return (
    <ProductPage
      name="sprint-page"
      title={t`Product Backlog`}
      breadcrumb={breadcrumb}
      nocontent>
      <BacklogPageContent product={product} />
    </ProductPage>
  );
}
