import { t } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';

const s3Axios = axios.create();

const uploadS3 = (attachment, file, data, options) => {
  s3Axios.put(data.url, file, { headers: { 'Content-Type': file.type } }).then(s3res => {
    if (options.onUpload) options.onUpload(s3res.data);
    axios.put(`/item-attachments/${attachment.pk}`, {
      file: data.file
    }).then(res => {
      if (options.onEditAttachment) options.onEditAttachment(res.data);
    }).catch(err => {
      if (options.onError) options.onError(err);
      addMessage(
        'update-attachment-failed',
        t`Unknown error`,
        t`Impossible to update attachment.`,
      );
    });
  }).catch(err => {
    axios.delete(`/item-attachments/${attachment.pk}`).catch(() => {
      addMessage(
        'update-attachment-failed',
        t`Unknown error`,
        t`Impossible to delete attachment.`,
      );
    });
    if (options.onError) options.onError(err);
    addMessage(
      's3-post-failed',
      t`Unknown error`,
      t`Impossible to upload file.`,
    );
  });
};

const loadFile = (attachment, file, options) => {
  axios.get(
    `/item-attachments/${attachment.pk}` +
    `/file/upload-url/${encodeURIComponent(file.name)}` +
    `?content-type=${encodeURIComponent(file.type)}`
  ).then(res => {
    uploadS3(attachment, file, res.data, options);
  }).catch(err => {
    if (options.onError) options.onError(err);
    addMessage(
      'get-upload-url-failed',
      t`Unknown error`,
      t`Impossible to get upload URL.`,
    );
  });
};

const uploadFile = (file, settings) => {
  const options = {
    onCreateAttachment: null,
    onUpload: null,
    onEditAttachment: null,
    onError: null,
    item: null,
    ...settings
  };
  axios.post('/item-attachments', {
    item: options.item,
  }).then(res => {
    if (options.onCreateAttachment) options.onCreateAttachment(res.data);
    loadFile(res.data, file, options);
  }).catch(err => {
    if (options.onError) options.onError(err);
    addMessage(
      'create-attachment-failed',
      t`Unknown error`,
      t`Impossible to create attachment.`,
    );
  });
};

export default uploadFile;
