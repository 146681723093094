import React, { useEffect, useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { addMessage } from 'components/ui/Messages';
import ContentBlock from 'components/content/ContentBlock';
import { LoaderContainer } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import { ConfirmButton } from 'components/utils';
import uploadFile from 'components/utils/uploadFiles';
import ImageViewer from 'components/ui/ImageViewer';
import { setStateItem } from 'utils/Item';

function AttachmentDropzone(props) {
  const { backlogItem } = props;
  const [uploading, setUploading] = useState(false);

  const handleFiles = acceptedFiles => {
    setUploading(true);
    acceptedFiles.map(f => {
      uploadFile(f, {
        onEditAttachment: data => {
          setUploading(false);
          setStateItem('backlog-items:' + backlogItem.pk, { attachment_list: [...backlogItem.attachment_list, data] });
        },
        onError: err => setUploading(false),
        item: backlogItem.pk,
      });
      return f;
    });
  };

  return (
    <>
      {uploading && (
        <LoaderContainer/>
      )}
      <Dropzone onDrop={handleFiles}>
        {({ getRootProps, getInputProps }) => (
          <section className="mt-2">
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <Trans>Drag ’n’ drop some files here, or click to select files</Trans>
            </div>
          </section>
        )}
      </Dropzone>
    </>
  );
}

function Attachment(props) {
  const { attachment, onDelete, setImageSelected, imageList } = props;
  const contentType = attachment.file['content-type'];
  let icon = 'file-earmark';
  let isImage = false;
  if (contentType) {
    if (contentType.startsWith('text/')) {
      icon = 'file-earmark-text';
    } else if (contentType.startsWith('image/')) {
      isImage = true;
      icon = 'file-earmark-image';
    } else if (contentType.startsWith('audio/')) {
      icon = 'file-earmark-music';
    } else if (contentType.startsWith('video/')) {
      icon = 'file-earmark-play';
    } else if (contentType === 'application/pdf') {
      icon = 'file-earmark-pdf';
    } else if ([
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet'
    ].includes(contentType)) {
      icon = 'file-earmark-excel';
    } else if ([
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.text'
    ].includes(contentType)) {
      icon = 'file-earmark-word';
    } else if ([
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.presentation'
    ].includes(contentType)) {
      icon = 'file-earmark-ppt';
    }
  }

  const css = {};
  if (isImage) {
    css.backgroundImage = 'url(' + process.env.REACT_APP_FILE_URL +
      '/images/small/' +
      attachment.file.key + ')';
  }

  useEffect(() => {
    if (isImage) {
      imageList.current.push({
        button: attachment.file.key,
        link: `${process.env.REACT_APP_FILE_URL}/images/large/${attachment.file.key}`,
        source: `${process.env.REACT_APP_FILE_URL}/${attachment.file.key}`,
      });
    }
  }, [imageList, attachment.file.key, isImage]);

  return (
    <div className="attachment-list-item" style={css}>
      { isImage ? (
        <button
          onClick={e => { setImageSelected(attachment.file.key); }}
          type="button"
          className="btn-simplelink"
          title={attachment.resource_name}
          href={`${process.env.REACT_APP_FILE_URL}/${attachment.file.key}`}>
          <span className="title">
            {attachment.resource_name}
          </span>
        </button>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          title={attachment.resource_name}
          href={`${process.env.REACT_APP_FILE_URL}/${attachment.file.key}`}>
          <Icon name={icon}/>
          <span className="title">
            {attachment.resource_name}
          </span>
        </a>
      ) }
      <div className="actions">
        <ConfirmButton
          variant="transparent"
          message={t`Are you sure you want to delete this element?`}
          confirmLabel={t`Delete`}
          confirmVariant="danger"
          onConfirm={() => onDelete(attachment.pk)}>
          <Icon name="x-circle" title={t`Delete`}></Icon>
        </ConfirmButton>
      </div>
    </div>
  );
}

function AttachmentList(props) {
  const { backlogItem } = props;
  const [, setRefresh] = useState(0);
  const [imageSelected, setImageSelected] = useState(null);
  const imageList = useRef([]);

  const handleDelete = pk => {
    axios.delete(`/item-attachments/${pk}`).then(() => {
      backlogItem.attachment_list = backlogItem.attachment_list.filter(
        a => a.pk !== pk);
      setRefresh(current => current + 1);
    }).catch(() => {
      addMessage(
        'update-attachment-failed',
        t`Unknown error`,
        t`Impossible to delete attachment.`,
      );
    });
  };

  return (
    <div className="attachment-list d-flex flex-row flex-wrap">
      { imageSelected && (
        <ImageViewer
          imageList={imageList.current}
          image={imageSelected}
          setImage={setImageSelected} />
      ) }
      {backlogItem.attachment_list.map(att => (
        <Attachment
          key={att.pk}
          attachment={att}
          onDelete={handleDelete}
          imageList={imageList}
          setImageSelected={setImageSelected}/>
      ))}
    </div>
  );
}

export default function BacklogItemAttachments(props) {
  return (
    <ContentBlock
      title={t`Attachments`}
      className="itemlinks bordered small-head mt-2 mb-2">
      <AttachmentList {...props}/>
      { !props.readonly && (
        <AttachmentDropzone {...props}/>
      ) }
    </ContentBlock>
  );
}
