import { t } from '@lingui/macro';
import { modelsApi } from 'components/utils/constants';
import Entity from 'entity/Entity';

export default class Product extends Entity {
  constructor() {
    super(['organizationSlug', 'productSlug'],
      modelsApi.Product + '/slug/:organizationSlug/:productSlug',
      modelsApi.Product,
      [
        'pk',
        'name',
        'prefix',
        'organization',
        'mode',
        'estimate_mode',
        'team_member',
        'team_restricted',
        'default_account',
        'product_vision',
        'product_goal',
        'allowed_accounts'
      ]);
  }

  _getStateKey() {
    return this._genericApi + ':' + this.slug;
  }

  hasVision() {
    return this.product_vision && this.product_vision.trim().length > 0;
  }

  hasGoal() {
    return this.product_goal && this.product_goal.trim().length > 0;
  }

  getStatus(pk) {
    return this.statuses.find(el => el.pk === pk);
  }

  getStatusParams(keys) {
    const params = [];
    for (const status of this.statuses) {
      if (keys.includes(status.status_type.value)) {
        params.push('status=' + status.pk);
      }
    }
    return params.join('&');
  }

  getStatusByType(key) {
    for (const status of this.statuses) {
      if (key === status.status_type.value) {
        return status;
      }
    }
    return null;
  }

  get is_account_manager() {
    return this.member.roles.some(r => r[0] === 'role_account_manager');
  }

  get is_organization_manager() {
    return this.member.roles.some(r => r[0] === 'role_organization_manager');
  }

  get is_member() {
    return this.member.roles.some(r => r[0] === 'role_member');
  }

  get is_guest() {
    return this.member.roles.some(r => r[0] === 'role_guest');
  }

  get is_product_administrator() {
    return this.member.roles.some(
      r => r[0] === 'role_product_administrator') ||
      this.is_organization_manager;
  }

  get is_team_member() {
    return this.member.roles.some(r => r[0] === 'role_team_member');
  }

  get is_stakeholder() {
    return this.member.roles.some(r => r[0] === 'role_stakeholder');
  }

  get can_edit() {
    return this.is_product_administrator;
  }

  get can_delete() {
    return this.is_product_administrator;
  }

  get can_add_sprint() {
    return this.organization.is_active && (
      this.is_product_administrator || this.is_team_member);
  }

  get can_edit_sprint() {
    return this.organization.is_active && (
      this.is_product_administrator || this.is_team_member);
  }

  get can_delete_sprint() {
    return this.organization.is_active && this.is_product_administrator;
  }

  get can_export_items() {
    return this.is_product_administrator || this.is_team_member;
  }

  get can_print_items() {
    return this.is_product_administrator || this.is_team_member;
  }

  get can_add_item() {
    return this.organization.is_active && (
      this.is_product_administrator || this.is_team_member);
  }

  get can_edit_item() {
    return this.organization.is_active && (
      this.is_product_administrator || this.is_team_member);
  }

  get can_delete_item() {
    return this.organization.is_active && this.is_product_administrator;
  }

  get can_add_team_member() {
    return this.organization.is_active && this.is_product_administrator;
  }

  can_edit_team_member(team_member, user) {
    return this.organization.is_active && this.is_product_administrator &&
    team_member.member.user.pk !== user.pk;
  }

  can_delete_team_member(team_member, user) {
    return this.organization.is_active && this.is_product_administrator &&
    team_member.member.user.pk !== user.pk;
  }

  get can_add_comment() {
    return this.organization.is_active && (
      this.is_organization_manager || this.is_team_member ||
      this.is_stakeholder);
  }

  can_edit_comment(comment, user) {
    if (!this.organization.is_active) return false;
    if (this.is_product_administrator) {
      return true;
    }
    return (this.is_team_member || this.is_stakeholder) &&
      comment.author.user && comment.author.user.pk === user.pk;
  }

  can_delete_comment(comment, user) {
    return this.can_edit_comment(comment, user);
  }

  get can_add_item_type() {
    return this.organization.is_active && this.is_product_administrator;
  }

  get can_add_release() {
    return this.organization.is_active && this.is_product_administrator;
  }

  get can_add_item_status() {
    return this.can_add_item_type;
  }

  get can_see_worklogs() {
    return !this.is_guest;
  }

  get can_see_helpscout_links() {
    return !this.is_guest;
  }

  get can_see_versioning() {
    return !this.is_guest;
  }

  get can_see_estimates() {
    return !this.is_guest;
  }

  get can_see_history() {
    return !this.is_guest;
  }

  get enable_storymap() {
    return this.enable_story_maps;
  }

  get can_manage_storymaps() {
    return this.organization.is_active && this.is_product_administrator;
  }

  getPath() {
    return '/' + this.organization.resource_slug + '/' + this.resource_slug;
  }

  getLinks() {
    const prefix = this.getPath() + '/';
    const links = [
      {
        name: 'kanban',
        title: t`Kanban`,
        path: prefix + 'kanban'
      },
      {
        name: 'items',
        title: t`Search items`,
        path: prefix + 'items'
      }
    ];
    if (this.enable_backlog) {
      links.push(
        {
          name: 'product-backlog',
          title: t`Product Backlog`,
          path: prefix + 'product-backlog'
        }
      );
    }
    return links;

  }
}
