import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert } from 'react-bootstrap';
import { LoaderContainer } from 'components/ui/Loader';
import ProductPage from 'components/page/ProductPage';
import * as Pages from 'pages';
import Product from 'entity/Product';
import ProductContext from 'components/utils/ProductContext';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProdStats from 'components/product/ProdStats';
import BacklogProductHistory from 'components/product/history/BacklogProductHistory';
import SprintStats from 'components/product/SprintStats';
import SprintBurndown from 'components/product/SprintBurndown';
import ProductBurnup from 'components/product/ProductBurnup';
import ScrumVelocity from 'components/product/ScrumVelocity';
import ContentBlock from 'components/content/ContentBlock';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { addMessage } from 'components/ui/Messages';
import { useStateItem } from 'utils/Item';
import Icon from 'components/ui/Icon';
import SidebarProduct from 'components/sidebar/SidebarProduct';
import NotFoundPage from './NotFoundPage';

function ProductVision(props) {
  const { product } = props;

  if (!product.hasVision() && !product.can_edit) return '';

  const onUpdate = (field, value, item) => {
    product.loadFromObject(item, true);
  };

  return (
    <div className="d-flex mb-3">
      <h3 className="h6 mb-0 col-4"><Trans>Product vision</Trans></h3>
      <div className="ps-2 flex-grow-1">
        {product.can_edit ? (
          <FieldShowUpdate
            stateKey={product._getStateKey()}
            type="textarea"
            pk={product.pk}
            api={product._genericApi}
            field="product_vision"
            label={t`Product vision`}
            onSave={onUpdate}
            content={product.product_vision}
            attributes={{ id: 'product_vision' }}>
            { pr => (!pr.hasVision() ? (
              <span className="text-disabled"><em><Trans>Add product vision</Trans></em></span>
            ) : (
              <pre>{ pr.product_vision }</pre>
            )) }
          </FieldShowUpdate>
        ) : (
          <pre>{ product.product_vision }</pre>
        )}
      </div>
    </div>
  );
}

function ProductGoal(props) {
  const { product } = props;

  if (!product.hasGoal() && !product.can_edit) return null;

  return (
    <div className="d-flex">
      <h3 className="h6 mb-0 col-4"><Trans>Product goal</Trans></h3>
      <div className="ps-2 flex-grow-1">
        {product.can_edit ? (
          <FieldShowUpdate
            stateKey={product._getStateKey()}
            type="textarea"
            pk={product.pk}
            api={product._genericApi}
            field="product_goal"
            label={t`Product goal`}
            content={product.product_goal}
            attributes={{ id: 'product_goal' }}>
            { pr => (!pr.hasGoal() ? (
              <span className="text-disabled"><em><Trans>Add product goal</Trans></em></span>
            ) : (
              <pre>{ pr.product_goal }</pre>
            )) }
          </FieldShowUpdate>
        ) : (
          <pre>{ product.product_goal }</pre>
        )}
      </div>
    </div>
  );
}

function SprintGoal(props) {
  const { sprint } = props;

  if (!sprint || !sprint.goal) return null;
  return (
    <div className="d-flex">
      <h3 className="h6 mb-0 col-4"><Trans>{ sprint.name } goal</Trans></h3>
      <div className="ps-2 flex-grow-1">
        <pre>{ sprint.goal }</pre>
      </div>
    </div>
  );
}

function Dashboard(props) {
  const { product, organization, breadcrumb } = props;
  const [sprint, setSprint] = useState(null);

  useEffect(() => {
    axios.get('/sprints?meta=&current=true&product=' + product.pk).then(res => {
      if (res.data.items.length < 1) {
        setSprint(null);
      }
      else {
        setSprint(res.data.items[0]);
      }
    }).catch(err => {
      addMessage('current-sprint-load', t`Unknown error`, t`Impossible to load current sprint`);
    });
  }, [product.pk]);

  return (
    <ProductPage
      name="product-page"
      title={product.name}
      nocontent
      breadcrumb={[...breadcrumb, product.name]}>
      <div className="row">
        <div className="col-md-6">
          { (product.can_edit || product.hasVision() || product.hasGoal()) && (
            <ContentBlock className="p-3 mb-2">
              <ProductVision product={product} />
              <ProductGoal product={product} />
              <SprintGoal sprint={sprint} />
            </ContentBlock>
          ) }
          <BacklogProductHistory product={product} />
        </div>
        <div className="col-md-6">
          <ProdStats />
          { product.can_see_worklogs && (
            <SprintStats product={product} sprint={sprint}/>
          ) }
          { sprint && (
            <SprintBurndown product={product} sprint={sprint} />
          ) }
          { organization.has_feature('burnup_chart') && (
            <ProductBurnup product={product} />
          ) }
          { product.mode.value === 'scrum' && product.can_see_worklogs && (
            <ScrumVelocity product={product} sprints={5}/>
          ) }
        </div>
      </div>
    </ProductPage>
  );
}

export default function DashboardProductPage() {
  const { productSlug } = useParams();
  const organization = useContext(OrganizationContext);
  const [error, setError] = useState(null);
  const storedReduced = localStorage.getItem('menu-reduced');
  const [reduced, setReduced] = useState(storedReduced === null ? false : (storedReduced === 'true'));

  const classes = ['page', 'sidebar'];
  if (reduced) classes.push('sidebar-reduced');
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>
  ];

  const product = useStateItem('products:' + productSlug, true);
  const expandBtn = (
    <button 
      type="button"
      className="btn-simplelink sidebar-expand"
      aria-controls="main-sidebar"
      aria-expanded={reduced ? 'false' : 'true'}
      onClick={() => { setReduced(v => !v); }}>
      <Icon name="chevron-left" title={reduced ? t`Expand` : t`Collapse`}/>
    </button>
  );

  useEffect(() => {
    window.localStorage.setItem('menu-reduced', reduced ? 'true' : 'false');
  }, [reduced]);

  useEffect(() => {
    if (!product || product.slug !== productSlug) {
      const prod = new Product();
      prod.load(
        { organizationSlug: organization.slug,
          productSlug: productSlug }).then((response) => {
        if (prod.organization.pk !== organization.pk) {
          setError(prod.title);
        }
      }).catch((err) => {
        setError(err.response || err.message);
      });
    }

  }, [productSlug, organization.slug, organization.pk, product]);

  if (error) {
    if (error.status && error.status === 404) {
      return <NotFoundPage breadcrumb={breadcrumb} />;
    }
    return <Alert variant="danger">Impossible de charger : { error}</Alert>;
  }
  if (!product || productSlug !== product.slug) {
    return <LoaderContainer fullpage />;
  }
  return (
    <ProductContext.Provider value={product}>
      <div className={classes.join(' ')}>
        <SidebarProduct expandBtn={expandBtn} reduced={reduced} />
        <Switch>
          <Route exact path="/:orgSlug/:productSlug">
            <Dashboard organization={organization} product={product} breadcrumb={breadcrumb} />
          </Route>
          <Route exact path="/:orgSlug/:productSlug/team" component={Pages.ProductTeamPage} />
          <Route exact path="/:orgSlug/:productSlug/items" component={Pages.BacklogItemsPage} />
          <Route exact path="/:orgSlug/:productSlug/item/:reference" component={Pages.BacklogItemPage} />
          <Route exact path="/:orgSlug/:productSlug/kanban" component={Pages.KanbanPage} />
          { product.enable_backlog && (
            <Route exact path="/:orgSlug/:productSlug/product-backlog" component={Pages.BacklogPage} />
          ) }
          { product.enable_release && (
            <Route exact path="/:orgSlug/:productSlug/releases" component={Pages.ReleasesPage} />
          ) }
          <Route exact path="/:orgSlug/:productSlug/sprints" component={Pages.SprintsPage} />
          <Route exact path="/:orgSlug/:productSlug/sprints/:sprintPk" component={Pages.SprintBacklogPage} />
          <Route exact path="/:orgSlug/:productSlug/sprints/:sprintPk/review" component={Pages.SprintReviewPage} />
          <Route exact path="/:orgSlug/:productSlug/sprints/:sprintPk/retrospective" component={Pages.SprintRetrospectivePage} />
          <Route exact path="/:orgSlug/:productSlug/epics" component={Pages.EpicsPage} />
          <Route exact path="/:orgSlug/:productSlug/item-types" component={Pages.ItemTypesList} />
          <Route exact path="/:orgSlug/:productSlug/item-status" component={Pages.ItemStatusList} />
          <Route exact path="/:orgSlug/:productSlug/accounts" component={Pages.ProductAccountList} />
          <Route exact path="/:orgSlug/:productSlug/settings" component={Pages.ProductSettingsPage} />
          <Route exact path="/:orgSlug/:productSlug/estimates" component={Pages.EstimatesPage} />
          { product.enable_storymap && (
            <>
              <Route exact path="/:orgSlug/:productSlug/storymap" component={Pages.StoryMapsPage} />
              <Route exact path="/:orgSlug/:productSlug/storymap/:storyMapPk" component={Pages.StoryMapPage} />
            </>
          )}
          <Route>
            <NotFoundPage breadcrumb={breadcrumb} />
          </Route>
        </Switch>
      </div>
    </ProductContext.Provider>
  );
}
