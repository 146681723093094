import React, { useContext } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import ProductContext from 'components/utils/ProductContext';
import OrganizationContext from 'components/utils/OrganizationContext';
import Sidebar from './Sidebar';
import SidebarLinks from './SidebarLinks';

function SidebarProduct(props) {
  const product = useContext(ProductContext);
  const organization = useContext(OrganizationContext);
  const prefix = organization.slug + '/' + product.slug;

  const links = [
    { name: 'product-kanban', icon: 'kanban', to: '/' + prefix + '/kanban', text: t`Kanban` },
  ];
  if (product.enable_backlog) {
    links.push({ name: 'product-backlog', icon: 'view-stacked', to: '/' + prefix + '/product-backlog', text: t`Product Backlog` });
  }
  if (product.mode.value === 'scrum') {
    links.push({ name: 'product-sprints', icon: 'calendar-week', to: '/' + prefix + '/sprints', text: t`Sprints` });
  }
  if (product.enable_release) {
    links.push({ name: 'product-releases', icon: 'truck', to: '/' + prefix + '/releases', text: t`Releases` });
  }
  links.push({ name: 'items', icon: 'list-check', to: '/' + prefix + '/items', text: t`Search items` });
  if (product.can_see_estimates) {
    links.push({ name: 'estimate', icon: 'clipboard-data', to: `/${prefix}/estimates`, text: t`Estimates` });
  }
  if (product.enable_storymap) {
    links.push({ name: 'storymap', icon: 'signpost', to: `/${prefix}/storymap`, text: t`Storymap` });
  }
  const sublinks = [];
  if (product.can_edit) sublinks.push({ name: 'prod-edit', icon: 'gear', to: `/${prefix}/settings`, text: t`Settings` });
  sublinks.push({ name: 'epics', icon: 'collection', to: '/' + prefix + '/epics', text: t`Epics` });
  sublinks.push({ name: 'product-team', icon: 'people', to: `/${prefix}/team`, text: t`Team` });
  if (product.can_edit) {
    sublinks.push({ name: 'item-types', icon: 'tag', to: `/${prefix}/item-types`, text: t`Item types` });
    sublinks.push({ name: 'item-status', icon: 'layout-three-columns', to: `/${prefix}/item-status`, text: t`Item status` });
    sublinks.push({ name: 'accounts', icon: 'wallet2', to: `/${prefix}/accounts`, text: t`Accounts` });
  }
  links.push({ name: 'prod-settings', icon: 'gear', text: t`Settings`, children: sublinks });

  return (
    <Sidebar context={<div className="organization-name">{ product.organization.resource_name }</div>} {...props}>
      <Link className="product-context" to={'/' + organization.slug + '/' + product.slug}>
        <div className="small"><Trans>Product</Trans></div>
        <h2>{ product.name }</h2>
      </Link>
      <ul className="nav flex-column">
        <SidebarLinks links={links} />
      </ul>
    </Sidebar>
  );
}

export default SidebarProduct;
