import React, { useContext, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import axios from 'axios';
import ProductContext from 'components/utils/ProductContext';
import { manageFormErrors } from 'components/form/Form';

function printList(pks, report_title, include_links, searchParams) {
  const data = pks ? {
    report_title: report_title,
    include_links: include_links,
    pks: pks
  } : {
    report_title: report_title,
    include_links: include_links,
  };
  const url = 'backlog-items/print' + (searchParams ? ('?' + searchParams.toString()) : '');
  return axios.put(url, data);
}

function BulkPrintModalContent(props) {
  const { product, pks, setShow, requestParams } = props;
  const [loading, setLoading] = useState(false);
  const titleRef = useRef();
  const includeLinksRef = useRef();
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const fields = ['report_title', 'include_links'];

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    printList(pks, titleRef.current.value, includeLinksRef.current.checked, requestParams).then(data => {
      setLoading(false);
      addMessage(
        'print-sucess-' + data.data.job.pk,
        t`Print enqueued`,
        t`New print in queue. Please consult your job list to retrieve the file when finished.`
      );
      setShow(false);
    }).catch(err => {
      const { globalErrors, fieldsErrors } = manageFormErrors(fields, err);
      setLoading(false);
      setErrors(fieldsErrors);
      setError(globalErrors.map(er => er.error).join('<br/>'));
    });
  };

  useEffect(() => {
    if (product) {
      titleRef.current.value = product.resource_name;
      includeLinksRef.current.checked = true;
    }
  }, [product]);

  return (
    <form className="relative" method="PUT" action="" onSubmit={onSubmit}>
      { loading && <LoaderContainer /> }
      { error && (
        <div className="alert alert-danger mb-2" role="alert">
          { error }
        </div>
      ) }
      <div className="form-group mb-2">
        <div className="label">
          <Trans>Report title</Trans>
        </div>
        <Form.Control
          size="50"
          className="w-100"
          ref={titleRef}
          type="text"
          id="report-title"
          isInvalid={!!errors.report_title}/>
        { errors.report_title && (
          <div className="invalid-feedback">{ errors.report_title.join(' ') }</div>
        ) }
      </div>
      <div className="form-group mb-2">
        <Form.Check
          ref={includeLinksRef}
          type="checkbox"
          label={t`Include links`}
          id="include-links"
          isInvalid={!!errors.include_links}/>
        { errors.include_links && (
          <div className="invalid-feedback">{ errors.include_links.join(' ') }</div>
        ) }
      </div>
      <div className="form-group pt-2">
        <input type="submit" className="btn btn-primary me-2" value={t`Print`} />
        <input type="button" className="btn btn-outline-secondary" value={t`Cancel`} onClick={() => { setShow(false); }} />
      </div>

    </form>
  );
}

function BulkPrintModal(props) {
  const { pks, dropdownItem, count, requestParams, limit } = props;
  const product = useContext(ProductContext);
  const [show, setShow] = useState(false);
  if ((pks && pks.length < 1) || (!pks && !count)) {
    return null;
  }
  const size = count || pks.length;

  return (
    <>
      { limit && limit < count ? (
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip>
              <Trans>This action cannot be performed with more than { limit } elements.</Trans>
            </Tooltip>
          )}>
          <button
            type="button"
            className={'btn btn-outline-dark btn-disabled' + (dropdownItem ? ' dropdown-item' : '')}>
            <Trans>Print</Trans>
            <span className="badge text-bg-secondary ms-2">{ size }</span>
          </button>
        </OverlayTrigger>
      ) : (
        <button
          type="button"
          className={'btn btn-outline-dark' + (dropdownItem ? ' dropdown-item' : '')}
          onClick={() => { setShow(true); }}>
          <Trans>Print</Trans>
          {!dropdownItem && (
            <span className="badge text-bg-secondary ms-2">{ size }</span>
          )}
        </button>
      )}
      
      <Modal
        enforceFocus={false}
        show={show}
        onHide={() => { setShow(false); }}
        className="bulk-items-modal"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="backlog-title">
            <Trans>Print {size} items</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BulkPrintModalContent requestParams={requestParams} setShow={setShow} count={count} product={product} pks={pks} />
        </Modal.Body>
      </Modal>

    </>
  );
}
BulkPrintModal.defaultProps = {
  count: null,
  pks: null,
  dropdownItem: false,
  requestParams: new URLSearchParams()
};

export default BulkPrintModal;
