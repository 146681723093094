import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Button } from 'react-bootstrap';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import { Form, FormContext, FormContextProvider } from 'components/form';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';

function NewItemForm(props) {
  const { organization } = props;
  const { item, setItem } = useContext(FormContext);
  const history = useHistory();

  const handleSave = (data) => {
    history.push(`/${organization.slug}/${data.product.resource_slug}/item/${data.resource_reference}`);
  };

  useEffect(() => {
    setItem({ ...item, item_type: null });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.product]);

  return (
    <Form
      name="account-add-form"
      noValidate
      onSave={handleSave}>
      <Form.Many2One
        id="product"
        name="product"
        required
        api={'/products?organization=' + organization.pk}/>
      { item.product && item.product.pk && (
        <Form.Many2One
          id="item_type"
          name="item_type"
          key={(item.product ? item.product.pk : 0) + (item.item_type ? item.item_type.pk : 0)}
          api={`/item-types?product=${item.product.pk}`}
          required/>
      )}
      <div className="actions">
        <Button variant="primary" type="submit">
          <Trans>Create a new item</Trans>
        </Button>
      </div>
    </Form>
  );
}

export default function HelpscoutNewItemPage(props) {
  const organization = useContext(OrganizationContext);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    t`New item`
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    axios.get(`/integrations/helpscout/${organization.slug}/settings?only_settings=1`).then(res => {
      if (res.data.settings) {
        setFormData({
          product: res.data.settings.product,
          item_type: res.data.settings.item_type,
          ticket: params.get('ticket') });
      } else {
        setFormData({
          product: null,
          ticket: params.get('ticket') });
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      addMessage('load-helpscout-settings', t`Unknown error`, t`Can't load settings`);
    });
  }, [organization.slug]);

  if (loading) {
    return <LoaderContainer height="3" static/>;
  }

  return (
    <AdminPage
      name="helpscout-page helpscout-page-new-item"
      title={t`New item`}
      breadcrumb={breadcrumb}>
      <FormContextProvider item={formData} api="/integrations/helpscout/new-item">
        <NewItemForm organization={organization}/>
      </FormContextProvider>
    </AdminPage>
  );

}
