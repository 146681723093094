/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-object-spread */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Dropdown, FormSelect } from 'react-bootstrap';
import axios from 'axios';
import DateFormat from 'components/ui/DateFormat';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import { useParamsFilters } from 'components/filters/Filters';
import QueryParams from 'utils/QueryParams';
import { WorklogsContentTable, WorklogsContentTableDate } from './WorklogsContentTables';

function getApiParams(filters, monthString, endMonthString) {
  const apiParams = new URLSearchParams();
  let groupBy = [];
  if ('group_by' in filters) {
    groupBy = filters.group_by;
  }
  if ('group_by_date' in filters) {
    if (filters.group_by_date[0] === 'month') {
      groupBy.push('month');
      groupBy.push('year');
    }
    else {
      groupBy.push(filters.group_by_date[0]);
    }
  }
  if (groupBy.length > 0) {
    apiParams.append('group_by', groupBy.join(','));
  }
  if ('worker' in filters) {
    apiParams.append('worker', filters.worker);
  }
  if ('customer' in filters) {
    apiParams.append('customer', filters.customer);
  }
  apiParams.append('date_from', monthString);
  apiParams.append('date_to', endMonthString);
  if ('account' in filters) {
    apiParams.append('account', filters.account[0]);
  }
  if ('worker' in filters) {
    apiParams.append('worker', filters.worker[0]);
  }
  if ('product' in filters) {
    apiParams.append('product', filters.product[0]);
  }
  return apiParams;
}

function SelectApi(props) {
  const { api, updateVal, value, ...rest } = props;
  const [options, setOptions] = useState([{ value: '', label: t`Loading…` }]);

  useEffect(() => {
    axios.get(api).then(res => {
      const opts = [{ value: '', label: '-- ' + t`Choose` + ' --' }];
      for (let i = 0; i < res.data.items.length; i++) {
        opts.push({
          label: res.data.items[i].resource_name,
          value: res.data.items[i].pk
        });
      }
      setOptions(opts);
    }).catch(e => {
      addMessage(api, t`Unknown error`, t`Impossible to load option list`);
    });
  }, [api]);

  return (
    <select value={value} {...rest} onChange={e => { updateVal({ [rest.name]: e.target.value }); }}>
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

function BtnSwitch(props) {
  const { name, value, setValue, children } = props;
  const classes = ['btn'];
  classes.push(name === value ? 'btn-secondary' : 'btn-outline-secondary');

  return (
    <button type="button" className={classes.join(' ')} onClick={() => { setValue(name); }}>
      { children }
    </button>
  );
}

function DateSelector(props) {
  const { updateVal, dateTo, dateFrom, monthDelta, yearDelta } = props;
  const [mode, setMode] = useState(dateFrom ? 'dates' : (yearDelta !== null ? 'year' : 'month'));
  let startDate = null;
  let endDate = null;

  if (mode === 'year') {
    startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(0);
    startDate.setFullYear(startDate.getFullYear() - yearDelta);
    endDate = new Date(startDate.getTime());
    endDate.setFullYear(startDate.getFullYear() + 1);
    endDate.setDate(0);
  }
  else if (mode === 'month' || monthDelta === null || !dateFrom || !dateTo) {
    startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - monthDelta);
    endDate = new Date(startDate.getTime());
    endDate.setMonth(startDate.getMonth() + 1);
    endDate.setDate(0);
  }
  else {
    startDate = new Date(dateFrom);
    endDate = new Date(dateTo);
  }
  const startDateStr = startDate.toISOString().split('T')[0];
  const endDateString = endDate.toISOString().split('T')[0];
  const startInput = useRef();
  const endInput = useRef();

  const setMonthDelta = (delta) => {
    updateVal({ month_delta: delta });
  };
  const setYearDelta = (delta) => {
    updateVal({ year_delta: delta });
  };
  const setDates = () => {
    updateVal({
      date_from: startInput.current.value,
      date_to: endInput.current.value
    });
  };
  const setModeValue = name => {
    setMode(name);
    if (name === 'month') {
      updateVal({ date_to: null, date_from: null, year_delta: null, month_delta: 0 });
    }
    else if (name === 'year') {
      updateVal({ date_to: null, date_from: null, month_delta: null, year_delta: 0 });
    }
    else {
      updateVal({ month_delta: null, year_delta: null });
    }
  };

  let finalMode = mode;
  if (yearDelta === null && (monthDelta === null || monthDelta === 0)) finalMode = 'month';

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        { finalMode === 'year' && startDate.getFullYear() }
        { finalMode === 'month' && (monthDelta === null || monthDelta === 0 ? (
          <Trans>This month</Trans>
        ) : (
          <DateFormat date={startDate} month="long" year="numeric" />
        ))}
        { finalMode === 'dates' && (
          <>
            <DateFormat datestring={startDate}/>
            -
            <DateFormat datestring={endDate}/>
          </>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="menu-date-select">
        <div className="text-center pb-2">
          <div className="btn-group" role="group">
            <BtnSwitch name="month" value={finalMode} setValue={setModeValue}>
              <Trans>Month</Trans>
            </BtnSwitch>
            <BtnSwitch name="year" value={finalMode} setValue={setModeValue}>
              <Trans>Year</Trans>
            </BtnSwitch>
            <BtnSwitch name="dates" value={finalMode} setValue={setModeValue}>
              <Trans>Dates</Trans>
            </BtnSwitch>
          </div>
        </div>
        { mode === 'month' && (
          <div className="menu-date-select-header">
            <button
              className="btn btn-icon text-muted me-2"
              type="button"
              onClick={() => { setMonthDelta(monthDelta === null ? 1 : monthDelta + 1); }}>
              <Icon name="arrow-left-square" />
            </button>
            {monthDelta === 0 || monthDelta === null ? (
              <Trans>This month</Trans>
            ) : (
              <DateFormat date={startDate} month="long" year="numeric" />
            )}
            {monthDelta > 0 && (
              <button
                className="btn btn-icon text-muted ms-2"
                type="button"
                onClick={() => { setMonthDelta(monthDelta - 1); }}>
                <Icon name="arrow-right-square" />
              </button>
            )}
          </div>
        )}
        { mode === 'year' && (
          <div className="menu-date-select-header">
            <button
              className="btn btn-icon text-muted me-2"
              type="button"
              onClick={() => { setYearDelta(yearDelta + 1); }}>
              <Icon name="arrow-left-square" />
            </button>
            {yearDelta === 0 ? (
              <Trans>This year</Trans>
            ) : startDate.getFullYear()}
            {yearDelta > 0 && (
              <button
                className="btn btn-icon text-muted ms-2"
                type="button"
                onClick={() => { setYearDelta(yearDelta - 1); }}>
                <Icon name="arrow-right-square" />
              </button>
            )}
          </div>
        )}
        { mode === 'dates' && (
          <>
            <span className="input-wrapper">
              <label htmlFor="date_from"><Trans>Date from</Trans></label>
              <input
                type="date"
                className="form-control"
                id="date_from"
                name="date_from"
                ref={startInput}
                defaultValue={startDateStr}/>
            </span>
            <span className="input-wrapper">
              <label htmlFor="date_to"><Trans>Date to</Trans></label>
              <input
                type="date"
                className="form-control"
                id="date_to"
                name="date_to"
                ref={endInput}
                defaultValue={endDateString}/>
            </span>
            <div className="text-center pt-2">
              <button type="button" className="btn btn-outline-secondary" onClick={setDates}>
                <Trans>Apply</Trans>
              </button>
            </div>
          </>
        ) }
      </Dropdown.Menu>
    </Dropdown>
  );
}

function GroupByInput(props) {
  const { filters, title, name, setGroupBy } = props;
  const id = 'group_' + name;

  const attrs = {
    id: id,
    name: id,
  };
  const isActive = ('group_by' in filters) && filters.group_by.includes(name);
  attrs.checked = isActive;

  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="checkbox"
        value={id || ''}
        onChange={(e) => { setGroupBy(name, !isActive); }}
        {...attrs} />
      <label className="form-check-label" htmlFor={id}>
        { title }
      </label>
    </div>
  );
}

function WorklogsContent(props) {
  const { hideExport, organization, user } = props;
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [groupByDate, setGroupByDate] = useState(false);
  const { history, filters } = useParamsFilters();
  const groupBy = 'group_by' in filters ? filters.group_by : [];

  const updateVal = useCallback(vals => {
    const params = new QueryParams();
    for (const i in vals) {
      if (params.has(i)) {
        if (vals[i] || vals[i] === 0) {
          params.set(i, vals[i]);
        }
        else {
          params.delete(i);
        }
      }
      else if (vals[i] || vals[i] === 0) {
        params.appendOrIgnore(i, vals[i]);
      }

    }
    history.push({ search: params.toString() });
  }, [history]);

  const setGroupBy = useCallback((name, checked) => {
    const params = new QueryParams();
    if (!checked) {
      params.deleteNameValue('group_by', name);
    }
    else {
      params.appendOrIgnore('group_by', name);
    }
    history.push({ search: params.toString() });
  }, [history]);

  const monthDelta = 'month_delta' in filters ? parseInt(filters.month_delta[0], 10) : null;
  const yearDelta = 'year_delta' in filters ? parseInt(filters.year_delta[0], 10) : null;

  let startDateStr = null;
  let endDateStr = null;
  if (!('date_from' in filters)) {
    const month = new Date();
    month.setDate(1);
    if (yearDelta !== null) {
      month.setMonth(0);
      month.setFullYear(month.getFullYear() - yearDelta);
    }
    else {
      month.setMonth(month.getMonth() - monthDelta);
    }

    const endMonth = new Date(month.getTime());
    if (yearDelta !== null) {
      endMonth.setFullYear(endMonth.getFullYear() + 1);
    }
    else {
      endMonth.setMonth(month.getMonth() + 1);
    }
    endMonth.setDate(0);
    startDateStr = month.toISOString().split('T')[0];
    endDateStr = endMonth.toISOString().split('T')[0];
  }
  else if (!('date_to' in filters)) {
    startDateStr = filters.date_from;
    const endMonth = new Date(startDateStr);
    endMonth.setMonth(endMonth.getMonth() + 1);
    endMonth.setDate(0);
    endDateStr = endMonth.toISOString().split('T')[0];
  }
  else {
    startDateStr = filters.date_from;
    endDateStr = filters.date_to;
  }

  useEffect(() => {
    setLoading(true);
    const apiParams = getApiParams(filters, startDateStr, endDateStr);

    if (organization) apiParams.append('organization', organization.pk);
    if (user) apiParams.append('user', user.pk);
    axios.get('/worklogs/analysis', { params: apiParams }).then(res => {
      setLoading(false);
      setGroupByDate('group_by_date' in filters ? filters.group_by_date[0] : false);
      setResults(res.data.items);
    }).catch(err => {
      setLoading(false);
      addMessage('worklog-load', t`Unknown error`, t`Impossible to load worklogs analysis`);
    });
  }, [filters, organization, startDateStr, endDateStr, user]);

  const exportFile = format => {
    const apiParams = getApiParams(filters, startDateStr, endDateStr);
    axios.get('/worklogs/export?format=' + format + '&' + apiParams.toString(), {
      responseType: 'arraybuffer'
    }).then(res => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers['content-type'] })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'worklog-export-' + new Date().toISOString().split('T')[0] + '.' + format
      );
      document.body.appendChild(link);
      link.click();
    }).catch(err => {
      addMessage('worklog-export', t`Unknown error`, t`Impossible to export worklogs`);
    });
  };

  return (
    <>
      <div className="filters">
        <form className="worklogs-filters row row-cols-lg-auto g-3 align-items-start" noValidate>

          <div className="col-12">
            <span className="label"><Trans>Dates</Trans></span>
            <DateSelector
              updateVal={updateVal}
              dateTo={'date_to' in filters ? filters.date_to[0] : null}
              dateFrom={'date_from' in filters ? filters.date_from[0] : null}
              monthDelta={'month_delta' in filters ? parseInt(filters.month_delta[0], 10) : null}
              yearDelta={'year_delta' in filters ? parseInt(filters.year_delta[0], 10) : null} />
          </div>
          { organization && (
            <>
              <div className="col-12">
                <span className="input-wrapper">
                  <label htmlFor="worker"><Trans>Member</Trans></label>
                  <SelectApi
                    updateVal={updateVal}
                    className="form-select"
                    id="worker"
                    name="worker"
                    api={'/members?organization=' + organization.pk}
                    value={'worker' in filters ? filters.worker : ''}/>
                </span>
              </div>
              <div className="col-12">
                <span className="input-wrapper">
                  <label htmlFor="worker"><Trans>Customer</Trans></label>
                  <SelectApi
                    updateVal={updateVal}
                    className="form-select"
                    id="customer"
                    name="customer"
                    api={'/customers?organization=' + organization.pk}
                    value={'customer' in filters ? filters.customer : ''}/>
                </span>
              </div>
            </>
          ) }
          <div className="col-12">
            <span className="input-wrapper">
              <label htmlFor="account"><Trans>Account</Trans></label>
              <SelectApi
                updateVal={updateVal}
                className="form-select"
                id="account"
                name="account"
                api={'/accounts?state=active' + (organization ? `&organization=${organization.pk}` : '')}
                value={'account' in filters ? filters.account : ''}/>
            </span>
          </div>
          <div className="col-12">
            <span className="input-wrapper">
              <label htmlFor="account"><Trans>Product</Trans></label>
              <SelectApi
                updateVal={updateVal}
                className="form-select"
                id="product"
                name="product"
                api={'/products?state=active' + (organization ? `&organization=${organization.pk}` : '')}
                value={'product' in filters ? filters.product : ''}/>
            </span>
          </div>
          
          <div className="col-12">
            <div className="label"><Trans>Group by</Trans></div>
            { !user && (
              <GroupByInput
                filters={filters}
                title={t`Member`}
                name="worker"
                setGroupBy={setGroupBy}/>
            ) }
            <GroupByInput
              filters={filters}
              title={t`Account`}
              name="account"
              setGroupBy={setGroupBy}/>
            <GroupByInput
              filters={filters}
              title={t`Item`}
              name="item"
              setGroupBy={setGroupBy}/>
          </div>
          <div>
            <span className="input-wrapper">
              <label htmlFor="group_by_date"><Trans>Group by date</Trans></label>
              <FormSelect
                value={'group_by_date' in filters ? filters.group_by_date[0] : ''}
                onChange={e => {
                  updateVal({ group_by_date: e.target.value });
                }}
                as="select"
                id="group_by_date">
                <option value="">-- { t`Choose` } --</option>
                <option value="date">{ t`date` }</option>
                <option value="month">{ t`month` }</option>
                <option value="year">{ t`year` }</option>
              </FormSelect>
            </span>
          </div>
          { Object.keys(filters).length > 0 && (
            <div className="pt-4">
              <button type="button" className="btn btn-link" onClick={() => { history.push({ search: null }); }}>
                <Trans>Clear filters</Trans> <Icon name="x-circle"/>
              </button>
            </div>
          ) }
        </form>
      </div>

      <div className="relative">
        {loading && (
          <LoaderContainer/>
        )}
        <h3 className="mt-3">
          Work logs
        </h3>
        { results.length > 0 && !hideExport && (
          <>
            <button type="button" className="btn btn-outline-secondary" onClick={() => exportFile('xlsx')}>
              <Icon name="filetype-xlsx"/> <Trans>Export to Excel</Trans>
            </button>
            {' '}
            <button type="button" className="btn btn-outline-secondary" onClick={() => exportFile('csv')}>
              <Icon name="filetype-csv"/> <Trans>Export to CSV</Trans>
            </button>
          </>
        )}
        { results && (groupByDate ? (
          <WorklogsContentTableDate
            groupBy={groupBy}
            results={results}
            startDateStr={startDateStr}
            endDateStr={endDateStr} />
        ) : (
          <WorklogsContentTable groupBy={groupBy} results={results} />
        ))}
      </div>

    </>
  );
}

WorklogsContent.defaultProps = {
  hideExport: false
};

export default WorklogsContent;
