// eslint-disable-next-line import/prefer-default-export
export const apiSearchUrl = (api, options = {
  apiParams: null,
  limit: null,
  search: null,
}) => {

  const parameters = {
    mode: 'select',
    subset: 'offset',
    ...options.apiParams
  };
  const params = [];
  for (const p in parameters) {
    if (Array.isArray(parameters[p])) {
      for (const v of parameters[p]) {
        params.push(p + '=' + encodeURIComponent(v));
      }
    } else {
      params.push(p + '=' + encodeURIComponent(parameters[p]));
    }
  }
  if (options.limit) {
    params.push('limit=' + encodeURIComponent(options.limit));
  }
  if (options.search) {
    params.push('search=' + encodeURIComponent(options.search));
  }

  let url = api + (api.includes('?') ? '&' : '?');
  url += params.join('&');
  return url;
};
