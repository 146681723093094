/* eslint-disable spaced-comment */
import { t } from '@lingui/macro';

export const statuslist = ['backlog', 'todo', 'in_progress', 'done', 'closed'];
export const statuslistKanban = ['todo', 'in_progress', 'done'];
export const statusNames = {
  backlog: t`Backlog`,
  todo: t`To do`,
  in_progress: t`In progress`,
  done: t`Done`,
  closed: t`Closed`
};

export const currencySymbol = { USD: '$', EUR: '€', CHF: 'CHF' };

export const priceList = {
  starter: {
    monthly: { USD: 10, EUR: 10, CHF: 10 },
    yearly: { USD: 100, EUR: 100, CHF: 100 } },
  premium: {
    monthly: { USD: 6, EUR: 6, CHF: 6 },
    yearly: { USD: 60, EUR: 60, CHF: 60 } },
};

export const modelsApi = {
  Organization: 'organizations',
  Product: 'products',
  Customer: 'customers',
  Account: 'accounts',
  Sprint: 'sprints',
  Member: 'members',
  BacklogItem: 'backlog-items',
  User: 'users',
  Epic: 'epics',
  ItemType: 'item-types',
  Release: 'releases',
};
